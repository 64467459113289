import { useState, useEffect } from "react";
import { useLazyGetMeterSeenQuery } from "../redux/slices/meterApi";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CopyTextButton from "../utils/CopyTextButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import ModalDialog from "./meters/ModalDialog";
import theme from "../theme";
import { StyledDataGrid } from "../utils/StyledDataGrid";
const MeterSeenIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [getMetrSeen] = useLazyGetMeterSeenQuery();
  const meterSeen = useSelector((state) => state?.meterSlice?.meterSeen);
  const onMailClick = (msg) => {
    setMessage(msg);
    setModalMessage(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },
    {
      field: "gateway",
      headerName: t("gateway"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"50%"}
          >
            <Typography>{params?.row?.gateway?.serial}</Typography>
            <IconButton
              onClick={() => navigate(`/gateways/${params?.row?.gateway.id}`)}
            >
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "device",
      headerName: t("Contatore"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 4,
      renderCell: (params) => {
        console.log("huivrots", params);
        return (
          <>
            {params?.row?.device?.handled ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                width={"60%"}
                justifyContent={"space-between"}
              >
                <Typography>{params?.row?.device?.meter}</Typography>
                <IconButton
                  onClick={() => navigate(`/meters/${params?.row?.device.id}`)}
                >
                  <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
                </IconButton>
              </Box>
            ) : (
              <Typography>{params?.row?.device?.meter}</Typography>
            )}
          </>
        );
      },
    },

    {
      field: "last_telegram",
      headerName: t("last_telegram"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "frame",
      headerName: t("Frame"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {!params?.row?.handled ? (
              <IconButton onClick={() => onMailClick(params?.row?.frame)}>
                <DraftsIcon />
              </IconButton>
            ) : (
              <CloseIcon sx={{ fill: theme.palette.error.main, ml: 1 }} />
            )}
          </>
        );
      },
    },

    {
      field: "handled",
      headerName: t("handled_meters"),
      width: 90,
      sortable: false,
      flex: 1,

      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-end"}
            sx={{ mr: 2 }}
          >
            {params.value === true ? (
              <DoneIcon sx={{ fill: theme.palette.success.main }} />
            ) : (
              <CloseIcon sx={{ fill: theme.palette.error.main }} />
            )}
          </Box>
        );
      },
    },

    /* {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => navigate(`/meters/${params.value}`)}
            >
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    }, */
  ];
  const rows = meterSeen?.map((item, i) => ({
    id: i,
    gateway: {
      serial: item?.gateway_serial_number,
      id: item?.gateway_id,
    },
    device: {
      meter: `${item.manufacturer}:${item.version}:${item.type}  ${item?.serial}`,
      id: item.uuid,
      handled: item?.handled,
    },
    frame: item?.frame ?? "--",
    last_telegram: t("date_val", {
      val: new Date(item?.datetime_utc),
    }),
    handled: item?.handled,
  }));
  useEffect(() => {
    getMetrSeen({
      last_devices_seen_from_gateways: true,
      page: page,
      per_page: perPage,
    });
  }, []);
  useEffect(() => {
    console.log("message", message);
  }, [message]);
  return (
    <>
      <ModalDialog
        open={modalMessage}
        close={() => setModalMessage(false)}
        title={"Message"}
      >
        <Box sx={{ maxWidth: 900 }}>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                /*     color: theme.palette.primary.contrastText, */
                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
          <CopyTextButton text={message} />
        </Box>
      </ModalDialog>

      <Box sx={{ height: "60vh", width: "99%" }}>
        <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns ?? []}
          /* rowCount={allMessages.length} */
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          getRowHeight={() => "auto"}
        />
      </Box>
    </>
  );
};
export default MeterSeenIndex;
