import validator from "@rjsf/validator-ajv6";
/* import validator from "@rjsf/validator-ajv8"; */
import { Form } from "@rjsf/mui";
import { ErrorListProps } from "@rjsf/utils";
import { useUpdateMeterMutation } from "../../redux/slices/meterApi";
import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setMessage } from "../../redux/slices/snackbarSlice";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import Point from "../form/formComponents/Point";
import { meterActions } from "../../redux/slices/meterSlice";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import SecurityWidget from "../form/formComponents/SecurityWidget";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 0) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const MeterUpdate = () => {
  const [updateMeter, { isSuccess }] = useUpdateMeterMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sec = IwdFormAuthWrapper({ section: "security" });
  const fieldsToUpdate = useSelector(
    (state) => state?.meterSlice?.fieldsToUpdate
  );
  const [security, setSecurity] = useState(null);

  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const twinId = useSelector((state) => state?.meterSlice?.digitalTwinId);
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  useEffect(() => {
    setSecurity(sec);
  }, [sec]);
  const admSchema = security
    ? {
        title: "New Device",
        type: "object",
        properties: {
          security: {
            $id: "2",
            type: "array",
            title: t("security"),
            default: fieldsToUpdate?.security?.acl,
          },
          /*      iccid: {
        $id: "2",
        type: "string",
        title: t("iccid"),
        default: fieldsToUpdate.iccid,
      }, */
          address: {
            $id: "4",
            type: "string",
            title: t("street_address"),
            default: fieldsToUpdate?.address,
          },

          pod: {
            $id: "3",
            type: "string",
            title: t("POD"),
            default: fieldsToUpdate?.pod,
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: fieldsToUpdate?.time_zone,

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },

          tags: {
            $id: "10",
            title: t("tag"),
            type: "array",
            url: "tag",
            multiple: true,
            default: [...(fieldsToUpdate?.tags ?? [])],
            properties: {
              tags: {
                type: "number",
              },
            },
          },

          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: fieldsToUpdate?.coordinates.lat,
              },
              lng: {
                $id: "29",
                type: "number",
                default: fieldsToUpdate?.coordinates.lng,
              },
            },
          },
        },
      }
    : {
        title: "New Device",
        type: "object",
        properties: {
          address: {
            $id: "4",
            type: "string",
            title: t("street_address"),
            default: fieldsToUpdate?.address,
          },

          pod: {
            $id: "3",
            type: "string",
            title: t("POD"),
            default: fieldsToUpdate?.pod,
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: fieldsToUpdate?.time_zone,

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },

          tags: {
            $id: "10",
            title: t("tag"),
            type: "array",
            url: "tag",
            multiple: true,
            default: [...(fieldsToUpdate?.tags ?? [])],
            properties: {
              tags: {
                type: "number",
              },
            },
          },

          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: fieldsToUpdate.coordinates?.lat,
              },
              lng: {
                $id: "29",
                type: "number",
                default: fieldsToUpdate.coordinates?.lng,
              },
            },
          },
        },
      };
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
        default: fieldsToUpdate?.address,
      },

      pod: {
        $id: "3",
        type: "string",
        title: t("POD"),
        default: fieldsToUpdate?.pod,
      },

      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: fieldsToUpdate.coordinates?.lat,
          },
          lng: {
            $id: "29",
            type: "number",
            default: fieldsToUpdate.coordinates?.lng,
          },
        },
      },
    },
  };
  const fields = {
    point: Point,
    search: SearcheableSelect,
    serverSearch: SearchOnServer,

    security: SecurityWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "security",
      /* "iccid", */
      "serial",
      "pod",
      "address",
      "time_zone",
      "coordinates",
      "tags",
    ],

    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },

    tags: {
      "ui:field": "serverSearch",
    },

    security: {
      "ui:field": "security",
    },
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
      dispatch(meterActions.setMeter(null));
      navigate(`/meters/${id}`);
    }
  }, [isSuccess]);
  useEffect(() => {
    console.log("ischanging", formData);
  }, [formData]);

  const onSubmitClick = () => {
    const attributes =
      roles?.includes("admin") || roles?.includes("/admin")
        ? {
            fields: {
              class: fieldsToUpdate?.class,
              digital_twin_id: twinId,
              serial_number: fieldsToUpdate?.serial_number,
              ...formData,
              security:
                security && formData?.security?.length > 0
                  ? {
                      acl: [...formData?.security],
                      groups: [
                        "/network_adapter_manager_router",
                        "/codec_manager",
                      ],
                    }
                  : {
                      acl: [
                        {
                          name: "/admin",
                          scopes: ["Elixir.show", "Elixir.update"],
                        },
                      ],
                      groups: ["/admin"],
                    },
              coordinates: {
                coordinates: [
                  formData?.coordinates?.lng,
                  formData?.coordinates?.lat,
                ],
                type: "Point",
              },
            },
          }
        : {
            fields: {
              class: fieldsToUpdate?.class,
              digital_twin_id: twinId,
              serial_number: fieldsToUpdate?.serial_number,
              ...formData,

              coordinates: {
                coordinates: [
                  formData?.coordinates?.lng,
                  formData?.coordinates?.lat,
                ],
                type: "Point",
              },
            },
          };

    updateMeter({
      attributes: attributes,
      instanceId: id,
      twinId: twinId,
    });
  };
  return (
    <>
      {security !== null && fieldsToUpdate && (
        <Form
          schema={
            roles.includes("admin") || roles.includes("/admin")
              ? admSchema
              : schema
          }
          fields={fields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={onSubmitClick}
          validator={validator}
          uiSchema={uiSchema}
          /* widgets={widgets} */
          showErrorList={false}
          /* customValidate={customValidate} */
          noHtml5Validate

          /*  templates={{ ErrorListTemplate }} */
        />
      )}
    </>
  );
};
export default MeterUpdate;
