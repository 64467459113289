import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../theme";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { setDexPage } from "../../redux/slices/dexDataSourceSlice";
import { Tooltip, Typography } from "@mui/material";

const DexTable = ({ page, setPage, perPage }) => {
  const dexData = useSelector((state) => state?.dexDataSourceSlice?.dex);
  const rowCount = useSelector((state) => state?.dexDataSourceSlice?.count);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  /*   const page = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.page
  );
  const perPage = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.per_page
  ); */

  const columns =
    dexData?.length > 0 &&
    Object?.keys(dexData?.[0]).map((column) => {
      return {
        field: column,
        headerName: t(column),
        editable: false,
        sortable: false,
        hide: column == "id",
        flex: column == "message_date" || column == "inserted_at" ? 4 : 2,
        renderCell: (params) => {
          if (typeof params.value === "boolean") {
            return params.value ? <ErrorIcon sx={{ fill: "red" }} /> : "--";
          }
          if (column === "alarms") {
            const alarms = JSON.parse(params.value);
            return (
              <Box display={"flex"} flexWrap="wrap" width={"100%"}>
                {alarms.map((item) => (
                  <Chip
                    sx={{
                      background: theme.palette.error.light,
                      color: theme.palette.error.contrastText,
                      mt: 1,
                    }}
                    label={item}
                  />
                ))}
              </Box>
            );
          }
          if (typeof params.value === "object" && params.value) {
            return (
              <ul style={{ padding: 0 }}>
                {Object.keys(params.value).map((k) => (
                  <li>
                    {k}: {params.value[k]}
                  </li>
                ))}
              </ul>
            );
          }

          return params.value;
        },
      };
    });
  console.log("dexdata", dexData);
  const tableRows = dexData?.map((item, key) => ({
    id: key,
    device_id: item?.device_id,
    in_error: item?.in_error,
    inserted_at: t("dt", { val: new Date(item?.inserted_at) }),
    message_date: t("dt", { val: new Date(item?.message_date) }),
    parent_device_id: item?.parent_device_id,
    unit: item?.unit,
    volume: item?.volume,
    alarms: item?.alarms,
    class: item?.class,
    device_serial_number: item?.device_serial_number,
    gateway_serial_number: item?.gateway_serial_number,
    value: item?.value,
    count: item,
  }));

  return (
    <Box sx={{ height: 600 }}>
      <StyledDataGrid
        rows={tableRows?.length > 0 ? tableRows : []}
        columns={columns?.length > 0 ? columns : []}
        pageSize={perPage}
        rowCount={rowCount}
        page={page - 1}
        rowsPerPageOptions={[perPage]}
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        getRowHeight={() => "auto"}
        disableColumnMenu
        paginationMode="server"
      />
    </Box>
  );
};

export default DexTable;
