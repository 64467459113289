import { useEffect, useState } from "react";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";

import { Grid, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import MapLeaflet from "../metrics/MapLeaflet";

import { useTranslation } from "react-i18next";

import PasswordField from "material-ui-password-field";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";

const AntennasRegistry = ({ result }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const fields = result?.data?.data?.attributes?.fields;
  const [trigger, res, lastPromiseInfo] = useLazyGetDataQuery();
  const filteredType = res?.data?.data?.attributes?.filter(
    (item) => item.value == fields?.type
  );
  const key = result?.data?.data?.attributes?.fields?.identifier_type;
  useEffect(() => {
    if (result?.data) {
      trigger("plugin/en137577");
    }
  }, [result]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={8}
        >
          <Grid item xs={4}>
            <Typography style={{ width: "100%" }} variant="subtitle2">
              {t("key")} :
            </Typography>
            <Input
              style={{ width: "100%" }}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={fields?.key ?? "--"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("metrological_serial")} :
            </Typography>
            <Typography variant="subtitle1">
              {result?.data?.data?.attributes?.fields?.serial ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {result?.data?.data?.attributes?.fields?.identifier_type}
            </Typography>
            <Typography>
              {result?.data?.data?.attributes?.fields?.[key]}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("tags")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.tags?.map((item) => (
                <Chip label={t(item)} />
              ))}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("coordinates")} :</Typography>
            <Typography variant="subtitle1">{`Lat : ${fields?.coordinates?.coordinates[1]}`}</Typography>
            <Typography variant="subtitle1">{`Lng : ${fields?.coordinates?.coordinates[0]}`}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("instalation_data")} :{" "}
            </Typography>
            <Typography variant="subtitle1">
              {t("dt", {
                val: new Date(result?.data?.data?.attributes?.inserted_at),
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("rssi")} : </Typography>
            <Typography variant="subtitle1">{fields?.rssi ?? "--"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("dev_addr")} : </Typography>
            <Typography variant="subtitle1">
              {fields?.dev_addr ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("street_address")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.address ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("app_eui")} : </Typography>
            <Typography variant="subtitle1">
              {fields?.app_eui ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("dev_eui")} : </Typography>
            <Typography variant="subtitle1">
              {fields?.dev_eui ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("configuration_date")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields?.placing_data_utc
                ? t("dt", {
                    val: new Date(fields?.placing_data_utc),
                  })
                : "--"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("last_reading_value")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields?.last_reading
                ? `${fields?.last_reading?.value} ${fields?.last_reading?.unit} `
                : "--"}
            </Typography>
            <Typography variant="subtitle2">
              {fields?.last_reading
                ? t("dt", {
                    val: new Date(fields?.last_reading?.datetime_utc),
                  })
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("time_zone")} :</Typography>
            <Typography variant="subtitle1">
              {/* {fields?.time_zone ?? "--"} */}
              Europe/Rome
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("profile")} :</Typography>
            <Typography variant="subtitle1">
              {`${fields?.profile ?? "--"}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("mounted_upside_down")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields?.reverse === true ? "True" : "False"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("alarm_list")} :</Typography>
            <Typography variant="subtitle1">
              {fields?.alarms
                ? fields?.alarms.map((item) => (
                    <Chip color="error" label={item} />
                  ))
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("associated_groups")} :
            </Typography>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("associated_districts")} :
            </Typography>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("valve_status")} :</Typography>
            <Typography variant="subtitle1">{t("open")}</Typography>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={12}>
            <MapLeaflet
              pos={result?.data?.data?.attributes?.fields?.coordinates}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AntennasRegistry;
