import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dexApi } from "./dexApi";

const dataSources = [
  {
    name: "readings",
    fields: {
      device_id: {
        label: "DeviceId",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal", "count_distinct"],

      },
      parent_device_id: {
        label: "Parent Device Id",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },
      in_error: {
        label: "In Error",
        type: "boolean",

        valueSources: ["value"],
        // operators: ["sum", "equal"],
      },
      inserted_at: {
        label: "Date system entry",
        type: "datetime",

        valueSources: ["value"],
        //   operators: ["sum", "equal"],
      },
      message_date: {
        label: "Reading Date",
        type: "datetime",
        valueSources: ["value"],
      },
      unit: {
        label: "Unit",
        type: "string",
        valueSources: ["value"],
      },
      volume: {
        label: "Volume",
        type: "number",
        valueSources: ["value"],
        operators: [
          "sum",
          "count",
          "average",
          "not_equal",
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },
    },
  },

  {
    name: "water_meter_readings",
    fields: {
      device_id: {
        label: "DeviceId",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal", "count_distinct"],
      },

      device_serial_number: {
        label: "Device Serial number",
        type: "text",

        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },

      parent_device_id: {
        label: "Parent Device Id",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal", "count_distinct"],
      },
      gateway_serial_number: {
        label: "Gateway Serial number",
        type: "text",

        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },

      in_error: {
        label: "In Error",
        type: "boolean",

        valueSources: ["value"],
        //operators: ["sum", "equal"],
      },

      message_date: {
        label: "Reading Date",
        type: "datetime",
        valueSources: ["value"],
      },
      unit: {
        label: "Unit",
        type: "text",
        valueSources: ["value"],
      },
      class: {
        label: "class",
        type: "text",
        valueSources: ["value"],
        operators: ["not_equal", "equal", "like"],
      },
      alarms: {
        label: "alarms",
        type: "text",
        valueSources: ["value"],
        operators: ["like"],
      },
      value: {
        label: "Volume",
        type: "number",
        valueSources: ["value"],
        operators: [
          "sum",
          "count",
          "average",
          "not_equal",
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },
    },
  },
  {
    name: "gateway_meter_readings",
    fields: {
      device_id: {
        label: "DeviceId",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal", "count_distinct"],
      },

      serial_number: {
        label: "Device Serial number",
        type: "text",

        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },



      in_error: {
        label: "In Error",
        type: "boolean",

        valueSources: ["value"],
        //operators: ["sum", "equal"],
      },

      battery_level: {
        label: "battery level",
        type: "number",
        valueSources: ["value"],
        operators: [
          "not_equal",
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },
      uptime: {
        label: "uptime",
        type: "number",
        valueSources: ["value"],
        operators: [
          "not_equal",
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },

      alarms: {
        label: "alarms",
        type: "text",
        valueSources: ["value"],
        operators: ["like"],
      },

    },
  },
];
const initialState = {
  dataSources: dataSources,
  dexIndex: null,
  dexShow: null,
  dataSource: null,
  precompiledQuery: true,
  dexAttributes: {
    logic: null,
    data_source: null,
    page: null,
    per_page: null,
  },
  postOrUpdate: false,
  count: null,
  dex: null,
};

const dexDataSourceSlice = createSlice({
  name: "dexDataSourceSlice",
  initialState,
  reducers: {
    setDataSource(state, action) {
      debugger;
      state.dataSource = action.payload;
    },
    setDexAttributes(state, action) {
      state.dexAttributes = action.payload;
      state.postOrUpdate = true;
    },
    setDexPage(state, action) {
      state.dexAttributes.page = action.payload;
      state.postOrUpdate = true;
    },
    queryOff(state, action) {
      state.precompiledQuery = false;
    },
    clearDexShow(state, action) {
      state.dexShow = null;
      state.dex = null;
      state.dataSource = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dexApi.endpoints.postDex.matchFulfilled,
      (state, action) => {
        debugger;
        state.dex = action.payload.data.attributes.data;
        state.count = action.payload.data.attributes.meta.count;
        state.postOrUpdate = false;
      }
    );
    builder.addMatcher(dexApi.endpoints.postDex.matchRejected, (state) => {
      state.postOrUpdate = false;
    });
    builder.addMatcher(
      dexApi.endpoints.getDexIndex.matchFulfilled,
      (state, action) => {
        state.dexIndex = action.payload.data;
      }
    );
    builder.addMatcher(
      dexApi.endpoints.getDexShow.matchFulfilled,
      (state, action) => {
        state.dexShow = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      dexApi.endpoints.executeSavedDex.matchFulfilled,
      (state, action) => {
        state.dex = action.payload.data.attributes.data;
        state.count = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export const {
  setDataSource,
  setDexAttributes,
  setDexPage,
  queryOff,
  clearDexShow,
} = dexDataSourceSlice.actions;

export default dexDataSourceSlice.reducer;
