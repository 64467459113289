import { actions } from "./authSlice";
import { meterApi } from "./meterApi";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  commands: null,
  meterSeen: null,
  meterSeenCount: null,
  digitalTwinId: null,
  fieldsToUpdate: null,
  meters: null,
  meter: null,
  allReadings: null,
  allReadingsCount: null,
};

const meterSlice = createSlice({
  name: "meterSlice",
  initialState,
  reducers: {
    setDigitalTwinId(state, action) {
      state.digitalTwinId = action.payload;
    },

    setFieldsToUpdate(state, action) {
      state.fieldsToUpdate = action.payload;
    },
    setMeter(state, action) {
      state.meter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      meterApi.endpoints.getCommands.matchFulfilled,
      (state, action) => {
        state.commands = action.payload.data.filter(
          (item) => item.attributes.category === "command"
        );
      }
    );
    builder.addMatcher(
      meterApi.endpoints.getMeterSeen.matchFulfilled,
      (state, action) => {
        state.meterSeen = action.payload.data;
        state.meterSeenCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      meterApi.endpoints.getMeters.matchFulfilled,
      (state, action) => {
        state.meters = action.payload.data;
      }
    );
    builder.addMatcher(
      meterApi.endpoints.getMeter.matchFulfilled,
      (state, action) => {
        state.meter = action.payload.data;
      }
    );
    builder.addMatcher(
      meterApi.endpoints.getAllReadings.matchFulfilled,
      (state, action) => {
        state.allReadings = action.payload.data;
        state.allReadingsCount = action.payload.meta.count;
      }
    );
  },
});
export const meterActions = meterSlice.actions;
export default meterSlice.reducer;
