import React, { useEffect } from "react";
import L from "leaflet";
import DeviceIcon from "../DeviceIcon";
import GatewayIcon from "../svgIcons/GatewayIcon";
import { Skeleton } from "@mui/material";
import ReactDOMServer from "react-dom/server";

import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
} from "react-leaflet";

import DeviceSvg from "../../statics/icons/device.svg";
import theme from "../../theme";

const AntennasMap = ({ gatewayPos, devicePos, rssiPositions }) => {
  const deviceIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<DeviceIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  const gatewayIcon = (color, number) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<GatewayIcon color={color} />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  useEffect(() => {
    console.log("POSGA");
  }, [gatewayPos, devicePos]);

  if (devicePos?.length < 1 || !devicePos) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={650}
        animation="wave"
      />
    );
  }

  return (
    <MapContainer
      id="show-device-map"
      style={{ height: "100%", zIndex: 0 }}
      center={[devicePos[0], devicePos[1]]}
      zoom={13}
      scrollWheelZoom={true}
    >
      <ScaleControl />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/*       {devicePos.length > 0 && (
        <Marker
          icon={deviceIcon()}
          key={1}
          position={[devicePos[1], devicePos[0]]}
        ></Marker>
      )} */}
      {gatewayPos.length > 0 &&
        gatewayPos?.map((item, i) => (
          <Marker
            icon={gatewayIcon()}
            kei={i}
            position={[item[1], item[0]]}
          ></Marker>
        ))}
      {rssiPositions.length > 0 &&
        rssiPositions.map((item, i) => (
          <Marker
            icon={deviceIcon(item?.color)}
            kei={i}
            position={[item?.coordinates[0], item?.coordinates[1]]}
          >
            <Popup>
              <Box>
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                >{`Rssi:  ${item?.rssi}`}</Typography>
                <Typography
                  sx={{ color: theme.palette.primary.main }}
                >{`Spreading factor:  ${item?.spreading_factor}`}</Typography>
              </Box>
            </Popup>
          </Marker>
        ))}
    </MapContainer>
  );
};

export default AntennasMap;
