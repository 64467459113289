import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyGetAllrolesQuery } from "../../../redux/slices/aegisApi";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../../theme";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
const KeysWidget = (props) => {
  const [name, setName] = useState("");
  const [getRoles] = useLazyGetAllrolesQuery();
  const [scopes, setScopes] = useState("");
  const [addParam, setAddParam] = useState(false);
  const roles = useSelector((state) => state?.aegisSlice?.roles);
  const [parameters, setParameters] = useState(props?.schema?.default ?? []);

  const mappedRoles = roles?.map((item) => ({
    label: item,
  }));
  useEffect(() => {
    props?.onChange(parameters);
  }, [parameters]);
  const onSaveClick = () => {
    setParameters((prev) => [
      ...prev,
      {
        name: name,
        value: scopes,
      },
    ]);
    setName("");
    setScopes("");
    setAddParam(false);
  };
  useEffect(() => {
    if (!roles) {
      getRoles();
    }
  }, []);
  useEffect(() => {
    console.log("propsssdada", props);
  }, [props]);
  const onDeleteClick = (i) => {
    setParameters(parameters.filter((el, ind) => ind !== i));
  };
  useEffect(() => {
    console.log("scopes", parameters);
  }, [parameters]);
  const updateNameAtIndex = (index, name) => {
    const newParam = [...parameters];
    newParam[index] = { ...newParam[index], name: name };
    setParameters(newParam);
  };
  const updateScopesAtIndex = (index, newScopes) => {
    const newParam = [...parameters];
    newParam[index] = { ...newParam[index], value: newScopes };
    setParameters(newParam);
  };

  const mappedScopes = parameters?.map((item, i) => (
    <>
      <Grid item xs={6}>
        <TextField
          label="key"
          value={item?.name}
          onChange={(e) => updateNameAtIndex(i, e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={5.5}>
        <TextField
          label="value"
          value={item?.value}
          onChange={(e) => updateScopesAtIndex(i, e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={0.5}>
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton onClick={() => onDeleteClick(i)}>
            <DeleteIcon sx={{ fill: theme.palette.error.main }} />
          </IconButton>
        </Box>
      </Grid>
    </>
  ));
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Keys</AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {mappedScopes}
          {parameters.length > 0 && !addParam && (
            <>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  width={"99.5%"}
                  justifyContent={"flex-end"}
                >
                  <IconButton onClick={() => setAddParam(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </Grid>
            </>
          )}
          {(parameters.length == 0 || addParam) && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Grid>

              <Grid item xs={5.5}>
                <TextField
                  fullWidth
                  label="value"
                  onChange={(e) => setScopes(e.target.value)}
                  value={scopes}
                />
              </Grid>
              <Grid item xs={0.5}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <IconButton onClick={onSaveClick}>
                    <DoneIcon />
                  </IconButton>
                  {parameters.length > 0 && addParam && (
                    <IconButton onClick={() => setAddParam(false)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default KeysWidget;
