import { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

import theme from "../../../theme";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { v4 as uuidv4 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const bearer = localStorage.getItem("user_token");
const DropzoneWidget = (props) => {
  const navigate = useNavigate();
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const { t } = useTranslation();
  const formData = new FormData();

  const [operationStatus, setOperationStatus] = useState(null);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [uuid] = useState(uuidv4());
  const operationId = sessionStorage.getItem("uuid");

  const clearFile = () => {
    setFiles(null);
    props.setFile(null);
  };

  const uploadFile = () => {
    console.log(formData);
    const body = {
      operation_id: uuid,
      file: formData,
    };
    fetch(`${window.BASE_URL}/api/v1/altior/device/upload`, {
      method: "POST",
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        /*  "content-Type": undefined, */
        // "content-length": `${files[0].size}`,
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOperationStatus(data.operation_status))
      .catch((err) => console.error(err));
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    /*  console.log(status, meta, file); */
    debugger;
    setFiles(file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };
  useEffect(() => {
    if (files) {
      props.setFile(files);
    }
  }, [files]);
  useEffect(() => {
    if (files) {
      debugger;
      //   formData.append(files?.[0]?.name, files?.[0]);
      formData.append("file", files);
      formData.append("operation_id", uuid);
    }
  }, [files]);

  return (
    <Card sx={{ height: 200 }}>
      <CardContent>
        <Box sx={{ height: 200 }}>
          <Box
            sx={{
              width: "100%",
              height: 100,
              /*  "@media (width: 600px)": {
                width: appBarIsOpen ? 285 : 420,
              }, */
            }}
          >
            {files == null && (
              <Dropzone
                style={{ color: "red" }}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                accept=".xlsx"
                maxFiles={1}
                styles={{
                  dropzone: { overflow: "hidden", height: 170 },
                }}
                inputContent={t("import_file")}
              />
            )}
            {files !== null && (
              <Box
                display={"flex"}
                alignContent={"center"}
                sx={{ width: 880, height: 53 }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.palette.primary.main, mt: 1.4 }}
                >
                  {`File to upload : ${files?.name}`}
                </Typography>
                <Tooltip title="Delete file">
                  <IconButton onClick={clearFile} sx={{ ml: 2 }}>
                    <CloseIcon sx={{ fill: theme.palette.error.main }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {/* 
          <Button
            sx={{ mt: 6, fill: theme.palette.primary.main }}
            variant="contained"
            onClick={uploadFile}
            disabled={!files}
          >
            {t("upload")}
          </Button> */}
        </Box>
      </CardContent>
    </Card>
  );
};
export default DropzoneWidget;
