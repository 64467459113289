import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { usePostDigitalTwinInstanceMutation } from "../../redux/slices/QdtiSlice";
import { usePostMultipleKeysMutation } from "../../redux/slices/meterApi";
import { useDispatch, useSelector } from "react-redux";
import Point from "../form/formComponents/Point";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import SecurityWidget from "../form/formComponents/SecurityWidget";
import ParametersHandler from "../statistics/ParametersHandler";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import KeysWidget from "../form/formComponents/KeysWidget";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (element.name === "security" || element.name === "keys") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const AntennasCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [meterId, setMeterId] = useState();
  const [keys, setKeys] = useState(null);
  const [postKeys, { isSuccess: postKeysSuccess }] =
    usePostMultipleKeysMutation();
  const [postDigitalTwinInstance, { data, isLoading, error }] =
    usePostDigitalTwinInstanceMutation();
  const dispatch = useDispatch();
  const digitalTwinId = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.id;
  const fields = {
    point: Point,
    search: SearcheableSelect,
    key: KeysWidget,
    security: SecurityWidget,
  };
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      security: {
        $id: "2",
        type: "array",
        title: t("security"),
      },
      app_eui: {
        $id: "2",
        type: "string",
        title: t("app_eui"),
      },
      dev_eui: {
        $id: "2",
        type: "string",
        title: t("dev_eui"),
      },
      dev_addr: {
        $id: "2",
        type: "string",
        title: t("dev_addr"),
      },
      profile: {
        $id: "2",
        type: "string",
        title: t("profile"),
      },
      serial: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      },
      keys: {
        $id: "2",
        type: "array",
        title: t("keys"),
      },

      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },

      digital_twin: {
        $id: "7",

        mapFn: (result) =>
          result?.data?.data
            ?.map((item) => ({
              key: item.attributes?.id,
              label: `${item.attributes?.name} (${item.attributes?.version})`,
              value: `${item.attributes?.name} (${item.attributes?.version})`,
              type: item?.attributes?.digital_twin_fields?.type,
            }))
            ?.filter((item) => item?.type == 29),
        /*    stateSelector: classStateSelector, */

        url: "altior/digital_twin",
        type: "string",
        title: t("digital_twin"),
        dispatched: true,
      },

      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
    },
    /* required: [
      "key",
      "serial",
      "pod",
      "address",
      "coordinates",
      "tags",
      "time_zone",
      "digital_twin",
    ], */
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "profile",
      "serial",
      "address",
      "coordinates",
      "dev_addr",
      "app_eui",
      "dev_eui",
      "keys",
      "security",
    ],

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },
    id_type: {
      "ui:field": "search",
    },
    note: {
      "ui:options": {
        widget: "textarea",
        minRows: 12,
      },
    },
    digital_twin: {
      "ui:field": "search",
    },

    tags: {
      "ui:field": "serverSearch",
    },
    image: {
      "ui:field": "image",
    },
    keys: {
      "ui:field": "key",
    },
    security: {
      "ui:field": "security",
    },
  };
  function keysTransform(inputJson) {
    var resultJson = {};
    Object.values(inputJson).forEach(function (item) {
      resultJson[item.name] = item.value;
    });
    return resultJson;
  }
  const onSubmitClick = (formData) => {
    const key = formData.keys;
    setKeys(keysTransform(key));
    delete formData.keys;
    const body = {
      data: {
        attributes: {
          fields: {
            class: "lorawan ",
            active_network_adapters: [],
            network_adapters: [],
            digital_twin_id: digitalTwinId?.toString(),
            identifier_type: "dev_addr",
            ...formData,
            security: {
              acl: [...formData.security],
              groups: ["/network_adapter_manager_router", "/codec_manager"],
            },
            coordinates: {
              coordinates: [
                formData?.coordinates?.lng,
                formData?.coordinates?.lat,
              ],
              type: "Point",
            },
          },
        },
        type: "plugin",
      },
    };

    postDigitalTwinInstance(body);
  };
  useEffect(() => {
    if (data) {
      debugger;
      setMeterId(data?.data?.id);
    }
  }, [data]);
  useEffect(() => {
    console.log("metkey", meterId, keys);
  }, [meterId, keys]);
  useEffect(() => {
    if (meterId && keys) {
      const attributes = {
        values: {
          ...keys,
        },
      };
      postKeys({
        twinId: digitalTwinId,
        instanceId: meterId,
        attributes: attributes,
      });
    } else if (meterId && !keys) {
      navigate(`/probes/${meterId}`);
    }
  }, [meterId, keys]);
  useEffect(() => {
    if (postKeysSuccess) {
      navigate(`/probes/${meterId}`);
    }
  }, [postKeysSuccess]);
  useEffect(() => {
    console.log("antformdt", formData);
  }, [formData]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        /*  customValidate={customValidate} */
        noHtml5Validate

        /*  templates={{ ErrorListTemplate }} */
      />
    </Box>
  );
};
export default AntennasCreate;
