import { useEffect, useState } from "react";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";

import { Button, Grid, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import MapLeaflet from "./MapLeaflet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordField from "material-ui-password-field";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import { IwdAuthWrapper } from "../../MuiOverrides/IwdAuthWrapper";
import SecurityShow from "../form/formComponents/SecurityShow";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";

import { Box } from "@mui/material";
const Registri = ({ meter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const security = IwdFormAuthWrapper({ section: "security" });
  const [showPassword, setShowPassword] = useState(false);
  const fields = meter?.attributes?.fields;
  const [trigger, res, lastPromiseInfo] = useLazyGetDataQuery();
  const filteredType = res?.data?.data?.attributes?.filter(
    (item) => item.value == fields?.type
  );
  const offMap =
    fields?.coordinates?.coordinates[0] == 0 &&
    fields?.coordinates?.coordinates[1] == 0;
  const key = meter?.attributes?.fields?.identifier_type;
  /*  useEffect(() => {
    if (meter) {
      trigger("plugin/en137577");
    }
  }, [meter]); */

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    console.log("mtetete", meter);
  }, [meter]);
  return (
    <>
      {meter && (
        <Grid container>
          <Grid
            container
            alignItems="center"
            rowSpacing={5}
            direction="row"
            spacing={5}
            xs={offMap ? 12 : 8}
          >
            <Grid item xs={4}>
              <Typography style={{ width: "100%" }} variant="subtitle2">
                {t("key")} :
              </Typography>
              <Input
                style={{ width: "100%" }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={fields?.key ?? "--"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("metrological_serial")} :
              </Typography>
              <Typography variant="subtitle1">
                {meter?.attributes?.fields?.serial ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {meter?.attributes?.fields?.identifier_type}
              </Typography>
              <Typography>{meter?.attributes?.fields?.[key]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("tags")} :</Typography>
              <Typography variant="subtitle1">
                {fields?.tags?.map((item) => (
                  <Chip label={t(item)} />
                ))}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("coordinates")} :</Typography>
              <Typography variant="subtitle1">{`Lat : ${fields?.coordinates?.coordinates[1]}`}</Typography>
              <Typography variant="subtitle1">{`Lng : ${fields?.coordinates?.coordinates[0]}`}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("instalation_data")} :{" "}
              </Typography>
              <Typography variant="subtitle1">
                {t("dt", {
                  val: new Date(meter?.attributes?.fields?.inserted_at),
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("pod")} : </Typography>
              <Typography variant="subtitle1">{fields?.pod ?? "--"}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("diameter")} : </Typography>
              <Typography variant="subtitle1">
                {fields?.diameter ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("street_address")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.address ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("type")} : </Typography>
              <Typography variant="subtitle1">
                {filteredType?.[0]?.name ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("notes")} : </Typography>
              <Typography variant="subtitle1">
                {fields?.note ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("configuration_date")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.placing_data_utc
                  ? t("dt", {
                      val: new Date(fields?.placing_data_utc),
                    })
                  : "--"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("last_reading_value")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.last_reading
                  ? `${fields?.last_reading?.value} ${fields?.last_reading?.unit} `
                  : "--"}
              </Typography>
              <Typography variant="subtitle2">
                {fields?.last_reading
                  ? t("dt", {
                      val: new Date(fields?.last_reading?.datetime_utc),
                    })
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("time_zone")} :</Typography>
              <Typography variant="subtitle1">
                {fields?.time_zone ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("battery_level")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.battery_level
                  ? `${fields?.battery_level?.value} ${fields?.battery_level?.unit}`
                  : "--"}
              </Typography>
              <Typography variant="subtitle2">
                {fields?.battery_level
                  ? t("dt", {
                      val: new Date(fields?.battery_level?.datetime_utc),
                    })
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("mounted_upside_down")} :
              </Typography>
              <Typography variant="subtitle1">
                {fields?.reverse === true ? "True" : "False"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("alarm_list")} :</Typography>
              <Typography variant="subtitle1">
                {fields?.alarms
                  ? fields?.alarms.map((item) => (
                      <Chip color="error" label={item} sx={{ mt: 1, ml: 1 }} />
                    ))
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("associated_groups")} :
              </Typography>
              <Typography variant="subtitle1">--</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("associated_districts")} :
              </Typography>
              <Typography variant="subtitle1">--</Typography>
            </Grid>
            {fields?.valve_status_v2 && (
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("valve_status")} :
                </Typography>

                <Box width={"100%"} display={"flex"}>
                  {fields?.valve_status_v2?.map((item) => (
                    <Chip sx={{ ml: 1, mt: 1 }} label={t(item)} />
                  ))}
                </Box>
              </Grid>
            )}
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("iccid")} :</Typography>
              <Typography variant="subtitle1">{fields?.iccid}</Typography>
            </Grid>
            {fields?.valve_opening_angle &&
              fields?.valve_opening_angle !== null && (
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    {t("valve_opening_angle")} :
                  </Typography>
                  <Typography variant="subtitle1">
                    {`${fields.valve_opening_angle} %`}
                  </Typography>
                </Grid>
              )}
            {security && (
              <Grid item xs={12}>
                <SecurityShow security={fields?.security?.acl} />
              </Grid>
            )}
          </Grid>

          {!offMap && (
            <Grid container xs={4}>
              <Grid item xs={12}>
                <MapLeaflet pos={meter?.attributes?.fields?.coordinates} />
              </Grid>
            </Grid>
          )}
          <IwdAuthWrapper
            children={
              <Button
                onClick={() => navigate("update")}
                sx={{ mt: 2 }}
                variant="contained"
              >
                {t("update_meter")}
              </Button>
            }
            section={"meters_update"}
          />
        </Grid>
      )}
    </>
  );
};

export default Registri;
