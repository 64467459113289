import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { StyledDataGrid } from "../../../utils/StyledDataGrid";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../../theme";
import { IwdAuthWrapper } from "../../../MuiOverrides/IwdAuthWrapper";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Table = ({
  data,
  response,
  perPage,
  page,
  setPage,
  hideAccordion,
  path,
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateSelector = (type, id) => {
    if (type == "29") {
      navigate(`/probes/${id}`);
    } else if (type == "00") {
      navigate(`/gateways/${id}`);
    } else {
      navigate(`/meters/${id}`);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 2 },
    /*    {
      field: "class",
      headerName: t("class"),
      editable: false,
      sortable: false,
      flex: 3,
    }, */
    {
      field: "tag",
      headerName: t("tag"),
      flex: 4,

      sortable: false,

      renderCell: (params) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => <Chip label={t(item)} />)
          )}
        </Box>
        /*   <List>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => (
              <ListItem>
                <ListItemText primart={item} />
              </ListItem>
               <Chip label={t(item)} />
            ))
          )}
        </List> */
      ),
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,

      flex: 5,
      headerAlign: "left",
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,

      flex: 5,
      headerAlign: "left",
    },

    {
      field: "address",
      headerName: t("street_address"),
      type: "number",
      sortable: false,

      flex: 5,
      headerAlign: "left",
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,

      flex: 3,
      headerAlign: "left",
    },
    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,

      flex: 3,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,

      flex: 6,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper section={"meters_delete"}>
              <IconButton disabled sx={{ float: "right" }}>
                <DeleteIcon sx={{ ml: 2 }} />
              </IconButton>
            </IwdAuthWrapper>
            <IwdAuthWrapper
              children={
                <IconButton
                  onClick={() =>
                    navigateSelector(params?.value?.type, params?.value?.id)
                  }
                  sx={{ float: "right" }}
                >
                  <VisibilityIcon
                    sx={{
                      fill: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              }
              section={"meters_show"}
            />
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags.map((item) => item)
      : "--",
    metrological_serial: item?.attributes?.fields?.serial ?? "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--",
    diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--",
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: {
      id: item.id,
      type: item?.attributes?.fields?.type,
    },
  }));

  return (
    <Box sx={{ height: hideAccordion ? "60vh" : "39vh", width: "99%" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={response?.data?.meta?.count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          !response.isFetching && setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default Table;
