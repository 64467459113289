import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import GroupsSelector from "./GroupsSelector";
import ParametersHandler from "./ParametersHandler";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import { useEffect, useState } from "react";
import { useCreateStatisticMutation } from "../../redux/slices/statisticApi";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const StatisticCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [schm, setSchm] = useState(null);
  const [formData, setFormData] = useState({});
  const connectionTypeEnum = [{ const: "Postgres" }];
  const [createStatistic] = useCreateStatisticMutation();
  const [createdStatisticId, setCreatedStatisticId] = useState(null);
  const typeEnum = [{ const: "raw_sql" }, { const: "dex" }];
  const schema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
      },
      connection_type: {
        id: 2,
        type: "string",
        title: t("connection_type"),
        oneOf: [...connectionTypeEnum],
      },
      type: {
        id: 3,
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
      },
      sql: {
        id: 4,
        type: "string",
        title: t("sql"),
      },

      connection_parameters: {
        id: 5,
        type: "object",
        title: t("connection_parameters"),
      },
      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
    },
    required: [
      "name",
      "description",
      "connection_type",
      "type",
      "sql",
      "connection_parameters",
      "groups",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    sql: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    raw: {
      "ui:field": "search",
    },
    connection_parameters: {
      "ui:field": "parameters",
    },
    groups: {
      "ui:field": "groups",
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    "ui:order": [
      "name",
      "type",
      "groups",
      "connection_type",
      "raw",
      "description",
      "sql",

      "connection_parameters",
    ],
  };
  const fields = {
    parameters: ParametersHandler,
    groups: GroupsSelector,
    search: SearcheableSelect,
  };
  const onSubmitClick = (val) => {
    createStatistic(val)
      .unwrap()
      .then((data) => setCreatedStatisticId(data?.data?.attributes?.id))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (createdStatisticId) {
      navigate(`/statistics/${createdStatisticId}`);
    }
  }, [createdStatisticId]);
  const isObjectEmpty = (objectName) => {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };
  const customValidate = (formData, errors) => {
    if (!formData?.name) {
      errors?.name?.addError("is a required property");
    }
    if (!formData?.description) {
      errors?.description?.addError("is a required property");
    }
    if (!formData?.sql) {
      errors?.sql?.addError("is a required property");
    }
    if (!formData?.connection_type) {
      errors?.connection_type?.addError("is a required property");
    }
    if (!formData?.type) {
      errors?.type?.addError("is a required property");
    }
    if (formData?.groups?.length < 1) {
      errors?.groups?.addError("is a required property");
    }
    if (
      isObjectEmpty(formData?.connection_parameters) &&
      formData.type !== "dex"
    ) {
      errors?.connection_parameters?.addError("is a required property");
    }

    return errors;
  };

  useEffect(() => {
    if (formData.type === "dex") {
      const props = { ...schema.properties };
      const required = ["name", "description", "type", "raw", "groups"];
      props["raw"] = {
        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name}`,
            value: item.attributes?.id?.toString(),
          })),
        /*    stateSelector: classStateSelector, */

        url: "dex",
        type: "string",
        title: t("dex"),
        dispatched: true,
      };
      delete props["sql"];
      delete props["connection_parameters"];
      delete props["connection_type"];
      const newSchema = { ...schema, properties: props, required };
      setSchm(newSchema);
    } else {
      setSchm(null);
    }
  }, [formData]);
  return (
    <>
      <Form
        schema={schm ?? schema}
        fields={fields}
        formData={formData}
        customValidate={customValidate}
        showErrorList={false}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => {
          onSubmitClick(values.formData);
        }}
        validator={validator}
        uiSchema={uiSchema}
        noHtml5Validate
      />
    </>
  );
};

export default StatisticCreate;
