import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledDataGrid } from "../../../utils/StyledDataGrid";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Form } from "@rjsf/mui";
import Chip from "@mui/material/Chip";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

import { useUpdateWorklistMutation } from "../../../redux/slices/worklistApi";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import DatePicker from "../../form/formComponents/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../theme";
import { useLazyGetWorklistShowQuery } from "../../../redux/slices/worklistApi";

import UserWidget from "../../survey/UserWidget";

import WorklistExportButtons from "../WorklistExportButtons";

import { setMessages } from "../../../redux/slices/messageSlice";
import { object } from "@jsonforms/examples";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (i == 2) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const WorklistShowAqp = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [firstRender, setFirstRender] = useState(true);
  const navigate = useNavigate();
  const [updateWorklist, { isSuccess: updateSuccess }] =
    useUpdateWorklistMutation();
  const { id } = useParams();
  const [getWorklist] = useLazyGetWorklistShowQuery();
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const worklist = useSelector((state) => state?.worklistSlice?.workList);
  const worklistDevices = useSelector(
    (state) => state?.worklistSlice?.workList
  )?.work_list_device;
  const { t } = useTranslation();
  const [formData, setFormData] = useState();
  const statusEnum = [
    { const: "downloaded" },
    { const: "running" },
    { const: "paused" },
    { const: "end" },
    { const: "uploaded" },
    { const: "inserted" },
  ];
  const fields = {
    datepicker: DatePicker,
    users: UserWidget,
    /*   wle: WorklistEditorSelectorWidget, */
  };
  useEffect(() => {
    console.log("worklistDevices", worklistDevices);
  }, [worklistDevices]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("successfully_updated")));
      getWorklist(id);
    }
  }, [updateSuccess]);

  useEffect(() => {
    getWorklist(id);
  }, [id]);
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "8",
        type: "string",
        title: t("name"),
        default: worklist?.name,
      },
      status: {
        $id: "8",
        type: "string",
        title: t("status"),
        /*    readonly: !roles.includes("admin") && true, */
        default: worklist?.status?.status,
        oneOf: [...statusEnum],
      },
      date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",
        fullscreen: true,

        properties: {
          from: {
            type: "string",
            default: worklist?.start_date,
          },
          to: {
            type: "string",
            default: worklist?.end_date,
          },
        },
      },
      /*   worklisteditor: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("worklisteditor"),
        default: worklist?.work_list_editor_id,
        update: true,
      }, */

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
        default: worklist?.user_id,
        update: true,
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("update"),

      props: {
        className: "bntListSearch",
      },
    },
    status: (!roles.includes("admin") || !roles.includes("/admin")) && {
      "ui:readonly": "true",
    },
    worklisteditor: {
      "ui:field": "wle",
    },
    date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
  };

  const onSubmitClick = () => {
    const currentDate = new Date();
    const formattedDate = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
        currentDate.getMilliseconds()
      )
    );
    const updatedData = {
      name: formData?.name,
      start_date: formData?.date?.from,
      end_date: formData?.date?.to,
      work_list_editor_id: formData?.worklisteditor,
      user_id: formData?.user_id,
      status: {
        date: formattedDate.toISOString(),
        status: formData?.status,
      },
    };
    updateWorklist({ attributes: updatedData, id: id });
    setFirstRender(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("Device"),
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"80%"}
            justifyContent={"space-between"}
          >
            <Typography>{params.value?.serial}</Typography>
            <IconButton
              onClick={() => navigate(`/meters/${params?.value?.id}`)}
            >
              <VisibilityIcon
                sx={{ fill: theme.palette.primary.main /*  ml: 3  */ }}
              />
            </IconButton>
          </Box>
        );
      },
    },

    {
      field: "value",
      headerName: t("Valore"),
      sortable: false,
      editable: false,
      flex: 1,
    },

    {
      field: "date",
      headerName: t("date"),
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "alarms",
      headerName: t("current_alarms"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="subtitle2">{params?.value}</Typography>;
      },
    },
    {
      field: "historic_alarm",
      headerName: t("yearly_alarms"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const alarms = params?.value
          ? Object.entries(params.value).filter((item) => item[1] > 0)
          : [];

        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {alarms?.map((item) => (
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 1,

                  fontWeight: item[1] > 1 && "bold",
                }}
              >{`${item[0]} : ${item[1]}`}</Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: "alarm_counter",
      headerName: t("comulative_day"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const alarms = params?.value
          ? Object.entries(params.value).filter((item) => item[1] > 0)
          : [];

        return (
          <Box display={"flex"} flexWrap="wrap" width={"100%"}>
            {alarms?.map((item) => (
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 1,

                  fontWeight: item[1] > 1 && "bold",
                }}
              >{`${item[0]} : ${item[1]}`}</Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: "event_alarm_counter",
      headerName: t("comulative_history"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const alarms = params?.value
          ? Object.entries(params.value).filter((item) => item[1] > 0)
          : [];

        return (
          <Box display={"flex"} flexWrap="wrap" width={"97%"}>
            {alarms?.map((item) => (
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 1,
                  mt: 1,
                  fontWeight: item[1] > 1 && "bold",
                }}
              >
                {`${item[0]} : ${item[1]} `}
              </Typography>
            ))}
          </Box>
        );
      },
    },
    /* {
      field: "state",
      headerName: t("Stato"),
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "right",
      renderCell: (params) => {
        if (!params?.value?.reading) {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              <Typography>--</Typography>
            </Box>
          );
        } else {
          if (params.value.length == 0) {
            return (
              <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                <DoneIcon sx={{ fill: theme.palette.success.main }} />
              </Box>
            );
          } else {
            return (
              <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                <CloseIcon sx={{ fill: theme.palette.error.main }} />
              </Box>
            );
          }
        }
      },
    }, */
  ];
  const rows = worklistDevices?.map((item) => ({
    id: item?.device_id,
    device: {
      id: item?.device_id,
      serial: item?.serial_number,
    },
    value: item?.decoded_message?.values?.last_reading?.value
      ? `${item?.decoded_message?.values?.last_reading?.value} ${item?.decoded_message?.values?.last_reading?.unit}`
      : "--",
    alarms: item?.decoded_message?.values?.alarms,
    historic_alarm: item?.decoded_message?.values?.historic_alarm,
    alarm_counter: item?.decoded_message?.values?.alarm_counter,
    event_alarm_counter: item?.decoded_message?.values?.event_alarm_counter,
    /*  state: {
      alarms: item?.decoded_message?.values?.alarms,
      reading: item?.decoded_message?.values?.last_reading?.value,
    }, */
    date: t("dt", { val: new Date(item.inserted_at) }),
  }));
  return (
    <>
      {worklist && (
        <Box height={950}>
          <Typography
            sx={{ color: theme.palette.primary.main, mb: 3 }}
            variant="h3"
          >{`Worklist: ${worklist?.name}`}</Typography>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={fields}
            formData={formData}
            validator={validator}
            onSubmit={onSubmitClick}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            /*  customValidate={customValidate} */
            noHtml5Validate
            showErrorList={false}
            children={true}
          >
            <Box sx={{ mt: 4 }} display={"flex"} justifyContent={"flex-end"}>
              <WorklistExportButtons id={id} name={worklist?.name} />
              <Button
                onClick={onSubmitClick}
                variant="contained"
                sx={{ ml: 2 }}
              >
                {t("update")}
              </Button>
            </Box>
          </Form>
          <Box sx={{ height: 550, mt: 4 }}>
            <StyledDataGrid
              disableColumnMenu
              rows={rows ?? []}
              columns={columns ?? []}
              rowCount={worklistDevices?.length}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              // loading={isLoadingTable}
              paginationMode="client"
              getRowHeight={() => "auto"}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default WorklistShowAqp;
