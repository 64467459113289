import { api } from "./api";
import {
  prepareBodyWithoutId,
  prepareParams,
  prepareBody,
} from "../../utils/api_params";
export const meterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCommands: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/${id}/attribute`;
      },
    }),
    postCommands: build.mutation({
      query: ({
        digitalTwinId,
        digitalTwinInstance,
        commandName,
        attributes,
      }) => ({
        method: "POST",
        url: `v1/altior/digital_twin/${digitalTwinId}/instance/${digitalTwinInstance}/command/${commandName}`,
        body: JSON.stringify(
          prepareBodyWithoutId(attributes, "device_commands")
        ),
      }),
    }),
    getMeters: build.query({
      query: (params) => {
        return `/v1/altior/device?${prepareParams(params)}`;
      },
    }),
    getMeter: build.query({
      query: (id) => {
        return `/v1/altior/digital_twin/0/instance/${id}`;
      },
    }),
    updateMeter: build.mutation({
      query: ({ attributes, twinId, instanceId }) => ({
        url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, instanceId, "devices")),
      }),
    }),
    getMeterSeen: build.query({
      query: (params) => {
        return `v1/message?${prepareParams(params)}`;
      },
      transformResponse: (response) => {
        console.log("rsasdadasda", response);
        const handledMeters = response?.data.map((msg) =>
          msg?.attributes?.values?.values.handled_meters?.map((meter) => {
            return {
              ...meter,
              gateway_serial_number: msg.attributes.serial_number,
              gateway_id: msg.attributes.device_id,
              handled: true,
            };
          })
        );

        const unhandledMeters = response?.data.map((msg) =>
          msg?.attributes?.values?.values.unhandled_meters?.map((meter) => ({
            ...meter,
            gateway_serial_number: msg.attributes.serial_number,
            gateway_id: msg.attributes.device_id,
            handled: false,
          }))
        );
        console.log("");
        const data = [...handledMeters.flat(), ...unhandledMeters.flat()];
        return { data: data, meta: response.meta };
      },
    }),
    getAllReadings: build.query({
      query: (params) => {
        return `v1/message?${prepareParams(params)}`;
      },
      transformResponse: (response) => {
        const data = response?.data.map((value) => {
          return {
            id: value.id,
            messageDate: value.attributes.message_date,
            deviceId: value.attributes.device_id,
            messageType: value.attributes.message_type,
            parentDeviceId: value.attributes.parent_device_id,
            deviceIdentifier: value?.attributes?.values?.device_identifier,
            valueType: value?.attributes?.values?.value_type,
            pod: value?.attributes?.extra?.pod,
            metrological_serial: value?.attributes?.extra?.metrological_serial,
            value:
              value?.attributes?.values?.values?.value?.last_reading?.value,
            unit:
              value?.attributes?.values?.values?.value?.last_reading?.unit ??
              value?.attributes?.values?.values?.last_reading?.unit ??
              "",
            strValue:
              value?.attributes?.values?.values?.value?.last_reading?.value ??
              value?.attributes?.values?.values?.last_reading?.value ??
              "--",
            // inAlarm: value?.attributes?.values?.value?.alarms.length > 0,
            alarms:
              value?.attributes?.values?.values?.value?.alarms ??
              value?.attributes?.values?.values?.alarms,
          };
        });
        return { data: data, meta: response.meta };
      },

      extraOptions: { maxRetries: 1 },
    }),
    postMultipleKeys: build.mutation({
      query: ({ twinId, instanceId, attributes }) => ({
        method: "POST",
        url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}/command/add_key`,
        body: JSON.stringify(
          prepareBodyWithoutId(attributes, "device_commands")
        ),
      }),
    }),
  }),
});
export const {
  useLazyGetCommandsQuery,
  useUpdateMeterMutation,
  usePostCommandsMutation,
  useLazyGetMeterSeenQuery,
  usePostMultipleKeysMutation,
  useLazyGetMetersQuery,
  useLazyGetMeterQuery,
  useLazyGetAllReadingsQuery,
} = meterApi;
