import { api } from "./api";
import { prepareParams } from "../../utils";

export const deviceMessageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeviceMessages: build.query({
      query: ({ digitalTwinId, deviceId, from, to, page, perPage }) => {
        return `v1/altior/digital_twin/${digitalTwinId}/instance/${deviceId}/message?from_date=${from}&to_date=${to}&page=${page}&per_page=${perPage}&message_type=reading&sort=message_date&order=desc`;
      },
      transformResponse: (response) => {
        const data = response?.data.map((value) => {
          return {
            id: value.id,
            messageDate: value.attributes.message_date,
            deviceId: value.attributes.device_id,
            messageType: value.attributes.message_type,
            parentDeviceId: value.attributes.parent_device_id,
            deviceIdentifier: value?.attributes?.values?.device_identifier,
            valueType: value?.attributes?.values?.value_type,
            value:
              value?.attributes?.values?.values?.value?.last_reading?.value,
            unit:
              value?.attributes?.values?.values?.value?.last_reading?.unit ??
              value?.attributes?.values?.values?.last_reading?.unit ??
              "",
            strValue:
              value?.attributes?.values?.values?.value?.last_reading?.value ??
              value?.attributes?.values?.values?.last_reading?.value ??
              "--",
            // inAlarm: value?.attributes?.values?.value?.alarms.length > 0,
            alarms:
              value?.attributes?.values?.values?.value?.alarms ??
              value?.attributes?.values?.values?.alarms,
          };
        });
        return { data: data, meta: response.meta };
      },

      extraOptions: { maxRetries: 1 },
    }),
    getAntennasMessages: build.query({
      query: ({ digitalTwinId, deviceId, page, perPage }) => {
        return `v1/altior/digital_twin/${digitalTwinId}/instance/${deviceId}/message?page=${page}&per_page=${perPage}`;
      },
    }),
  }),
});

export const {
  useLazyGetDeviceMessagesQuery,
  useLazyGetAntennasMessagesQuery,
} = deviceMessageApi;
