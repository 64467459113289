import { prepareParams } from "../../utils";
import { prepareBody, prepareBodyWithoutId } from "../../utils/api_params";
import { api } from "./api";

export const condominiumApi = api.injectEndpoints({
  endpoints: (build) => ({
    condominiumIndex: build.query({
      query: (params) => {
        return `v1/plugin/condominiums?${prepareParams(params)}`;
      },
    }),
    condominiumShow: build.query({
      query: (id) => {
        return `v1/plugin/condominiums/${id}`;
      },
    }),
    // deleteUser: build.mutation({
    //   query: (id) => ({
    //     method: "DELETE",
    //     url: `v1/user/${id}`,
    //   }),
    // }),
    createCondominium: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/condominiums",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "device")),
      }),
    }),
    updateCondominium: build.mutation({
      // <<<<<<< HEAD
      query: ({ attributes, id }) => ({
        url: `v1/plugin/condominiums/${id}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, id, "device")),
        //TODO: verificare se togliere
        // =======
        //       query: (attributes) => ({
        //         url: `v1/altior/digital_twin/6/instance/${attributes.id}`,
        //         method: "PATCH",
        //         body: JSON.stringify(prepareBody(attributes, "device")),
        // >>>>>>> a040614ece5f526a3047f9b1156e9df1f981c7b9
      }),
    }),
    createSubCondominium: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/sub_condominiums",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "device")),
      }),
    }),
    updateSubCondominium: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/plugin/sub_condominiums/${id}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, id, "device")),
      }),
    }),
    createApartment: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/apartments",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    getCondominiumId: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    getSubCondominiumId: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    getSubCondominiumTopElement: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    getSubCondominiumBotElement: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    getApartamentId: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    getMetertId: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/7/instance/${id}`;
      },
    }),
    associateMeter: build.mutation({
      query: ({ attributes, identifier }) => ({
        url: `v1/plugin/apartments/${identifier}/associate`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    associateGateway: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/plugin/sub_condominiums/${id}/associate`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    updateCondominiumIfno: build.mutation({
      query: ({ instanceId, attributes, twinId }) => ({
        url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, instanceId, "devices")),
      }),
    }),
    createInstance: build.mutation({
      query: ({ twinId, attributes }) => ({
        url: `v1/altior/digital_twin/${twinId}/instance`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "devices")),
      }),
    }),
    getCondominiumReadings: build.query({
      query: (params) => ({
        url: `v1/message?${prepareParams(params)}`,
      }),
    }),
    getSubCondominiumReadings: build.query({
      query: (params) => ({
        url: `v1/message?${prepareParams(params)}`,
      }),
    }),
    getApartamentReadings: build.query({
      query: (params) => ({
        url: `v1/message?${prepareParams(params)}`,
      }),
    }),
    getCondominiumConsumptions: build.query({
      query: (params) => ({
        url: `v1/plugin/consumptions?${prepareParams(params)}`,
      }),
    }),
    getSubcondominiumConsumtions: build.query({
      query: (params) => ({
        url: `v1/plugin/consumptions?${prepareParams(params)}`,
      }),
    }),
    getApartamentConsumtions: build.query({
      query: (params) => ({
        url: `v1/plugin/consumptions?${prepareParams(params)}`,
      }),
    }),
    updateCondominiumUpload: build.mutation({
      query: ({ id, attributes }) => ({
        url: `/v1/plugin/condominium_upload/${id}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, id, "plugin")),
      }),
    }),
    exportCondominiumReadings: build.mutation({
      query: (attributes) => ({
        url: `/v1/plugin/condominium_export`,
        method: "POST",
        body: JSON.stringify(prepareBody(attributes)),
        responseType: "blob",
      }),
      responseHandler: (response) =>
        response.blob().then((blob) => URL.createObjectURL(blob)),
    }),
  }),
});

export const {
  useLazyCondominiumIndexQuery,
  useLazyCondominiumShowQuery,
  useCreateCondominiumMutation,
  useCreateSubCondominiumMutation,
  useCreateApartmentMutation,
  useLazyGetCondominiumIdQuery,
  useLazyGetSubCondominiumIdQuery,
  useUpdateCondominiumMutation,
  useUpdateSubCondominiumMutation,
  useAssociateMeterMutation,
  useLazyGetCondominiumReadingsQuery,
  useLazyGetSubCondominiumReadingsQuery,
  useLazyGetApartamentReadingsQuery,
  useLazyGetCondominiumConsumptionsQuery,
  useLazyGetSubcondominiumConsumtionsQuery,
  useLazyGetApartamentConsumtionsQuery,
  useUpdateCondominiumIfnoMutation,
  useAssociateGatewayMutation,
  useLazyGetApartamentIdQuery,
  useLazyGetMetertIdQuery,
  useCreateInstanceMutation,
  useLazyGetSubCondominiumBotElementQuery,
  useLazyGetSubCondominiumTopElementQuery,
  useUpdateCondominiumUploadMutation,
  useExportCondominiumReadingsMutation,
} = condominiumApi;
