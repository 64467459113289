import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
//
// Set default locale

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng, formatOptions) => {
        try {
          if (format === "datetime") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "datetime_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "date_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(value);
          }
          if (format === "number") {
            if (formatOptions && formatOptions.maximumFractionDigits) {
              // Use the provided maximumFractionDigits option

              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: formatOptions.maximumFractionDigits,
              });
              return formattedNumber;
            } else {
              // Fallback to the default maximumFractionDigits (e.g., 2)
              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: 3,
              });
              return formattedNumber;
            }
          }
        } catch (ex) {
          return "-";
        }
        return value;
      },
    },
  });

export default i18n;
