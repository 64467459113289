import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv6";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { Form } from "@rjsf/mui";
import { useNavigate } from "react-router-dom";
import DropzoneWidget from "./DropzoneWidget";
import DatePicker from "../../form/formComponents/DatePicker";
import { worklistActions } from "../../../redux/slices/worklistSlice";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../theme";
import { loadingOff, loadingOn } from "../../../redux/slices/loadingSlice";
import WorklistEditorUploader from "../WorklistEditorUploader";
import { useCreateWorkListMutation } from "../../../redux/slices/worklistApi";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import { useDeleteWorklistMutation } from "../../../redux/slices/worklistApi";
import { setSeverity } from "../../../redux/slices/snackbarSlice";
import UserWidget from "../../survey/UserWidget";
import ModalDialog from "../../meters/ModalDialog";
import WorklistEditorSelectorWidget from ".././WorklistEditorSelectorWidget";
import { setErrors } from "../../../redux/slices/errorSlice";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const WorklistAqpCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteWorklist, { isSuccess: deleteSuccess }] =
    useDeleteWorklistMutation();
  const [createdErrors, setCreatedErrors] = useState(null);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const [worklistId, setWorklistId] = useState(null);
  const fileData = new FormData();
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState();
  const [createWorklist, { isSuccess: createSuccess }] =
    useCreateWorkListMutation();
  const fields = {
    datepicker: DatePicker,
    users: UserWidget,
    wle: WorklistEditorSelectorWidget,
  };
  useEffect(() => {
    dispatch(worklistActions.cleanWorklist());
  }, []);
  const closeModal = () => {
    setModal(false);
  };
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        $id: "8",
        type: "string",
        title: t("name"),
      },
      date: {
        $id: "3",
        title: t("inserted_at"),
        type: "object",

        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },

      user_id: {
        $id: "8",
        type: "string",
        title: t("user_id"),
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
      },
    },
    worklisteditor: {
      "ui:field": "wle",
    },
    date: {
      "ui:field": "datepicker",
    },
    user_id: {
      "ui:field": "users",
    },
  };
  useEffect(() => {
    console.log("fakd", formData);
  }, [formData]);
  const onSubmitClick = () => {
    const bearer = localStorage.getItem("user_token");
    fileData.append("file", file);
    fileData.append("name", formData?.name);
    fileData.append("start_date", formData?.date?.from);
    fileData.append("end_date", formData?.date?.to);
    fileData.append("user_id", formData?.user_id);
    dispatch(loadingOn());

    fetch(`${window.BASE_URL}/api/v1/plugin/sagemcom_aqp_upload`, {
      method: "POST",
      body: fileData,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          debugger;
          const errorText = await res.text();
          try {
            const errorJson = JSON.parse(errorText);
            const errorDetail = errorJson?.errors?.detail;
            throw new Error(
              `HTTP error! Status: ${res.status}, Detail: ${errorDetail}`
            );
          } catch (errorJson) {
            const detail = JSON.parse(errorText);
            throw new Error(
              `HTTP error! Status: ${res.status}, ${detail.errors?.detail}`
            );
          }
        }
        return res.json();
      })
      .then((data) => {
        dispatch(loadingOff());
        setWorklistId(data?.data?.attributes?.work_list?.id);
        setCreatedErrors(data?.data?.attributes?.errors);
      })
      .catch((err) => {
        debugger;

        console.error("Ошибка при запросе:", err);
        dispatch(loadingOff());
        dispatch(setSeverity("error"));
        dispatch(setMessage(err.message));
      });
  };

  useEffect(() => {
    if (worklistId && createdErrors?.length == 0) {
      navigate(`/worklistsaqp/${worklistId}`);
    } else if (worklistId && createdErrors?.length > 0) {
      setModal(true);
    }
  }, [worklistId]);
  useEffect(() => {
    console.log("creaerx", createdErrors);
  }, [createdErrors]);

  useEffect(() => {
    if (deleteSuccess) {
      setModal(false);
      /*      setFormData({});
      setFile(null); */
    }
  }, [deleteSuccess]);

  return (
    <Box>
      <Typography
        sx={{ color: theme.palette.primary.main, mb: 2 }}
        variant="h3"
      >
        {t("create_worklist")}
      </Typography>
      <ModalDialog
        title={t("wl_errors_on_create")}
        open={modal}
        close={closeModal}
        cancell={false}
      >
        <Box width={370}>
          {createdErrors?.map((item) => (
            <Typography>{`${item[1].split(":")[0]} : ${t(
              "device_not_found"
            )}`}</Typography>
          ))}
          <Box
            sx={{ mt: 4, width: "100%" }}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              onClick={() => deleteWorklist(worklistId)}
              variant="contained"
            >
              {t("delete_worklist")}
            </Button>
            <Button
              onClick={() => navigate(`/worklistsaqp/${worklistId}`)}
              variant="contained"
            >
              {t("go_to_worklist")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box height={200}>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              fields={fields}
              formData={formData}
              validator={validator}
              onSubmit={onSubmitClick}
              onChange={(changeEvent) => setFormData(changeEvent.formData)}
              /*  customValidate={customValidate} */
              noHtml5Validate
              showErrorList={false}
              children={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <DropzoneWidget setFile={setFile} file={file} />
          <Button
            onClick={onSubmitClick}
            disabled={
              !formData?.name || !formData?.date || !formData?.user_id || !file
            }
            sx={{ mt: 2, float: "right" }}
            variant="contained"
          >
            {t("create")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorklistAqpCreate;
