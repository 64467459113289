import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import DraftsIcon from "@mui/icons-material/Drafts";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TreeView from "@mui/lab/TreeView";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MuiDrawer from "@mui/material/Drawer";
import WidgetsIcon from "@mui/icons-material/Widgets";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AlarmIcon from "@mui/icons-material/Alarm";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import Divider from "@mui/material/Divider";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TreeItem from "@mui/lab/TreeItem";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import RadarIcon from "@mui/icons-material/Radar";
import ExploreIcon from "@mui/icons-material/Explore";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { v4 as uuidv4 } from "uuid";
import BoltIcon from "@mui/icons-material/Bolt";
import FolderIcon from "@mui/icons-material/Folder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PollIcon from "@mui/icons-material/Poll";
import SpeedIcon from "@mui/icons-material/Speed";
import DialpadIcon from "@mui/icons-material/Dialpad";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PeopleIcon from "@mui/icons-material/People";
import { IwdTreeItem } from "../MuiOverrides/IwdTreeItem";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import MapIcon from "@mui/icons-material/Map";
import { setHeaderAppBar } from "../redux/slices/utilsSlice";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import SecurityIcon from "@mui/icons-material/Security";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import KeyIcon from "@mui/icons-material/Key";
import { useDispatch, useSelector } from "react-redux";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import MailIcon from "@mui/icons-material/Mail";
import { useSignoutMutation } from "../redux/slices/authApi";
import { useLazyGetPreferenceQuery } from "../redux/slices/userPreferenceApi";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Email } from "@material-ui/icons";
import { actions } from "../redux/slices/authSlice";
import { WindowSharp } from "@mui/icons-material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import DvrIcon from "@mui/icons-material/Dvr";
import InsightsIcon from "@mui/icons-material/Insights";
import AppsIcon from "@mui/icons-material/Apps";

const drawerWidth = 270;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  [theme.breakpoints.up("sm")]: {
    width: `0`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const CustomListItem = styled(ListItem)(() => ({
  "&.MuiButtonBase-root": {
    padding: "7px",
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const logged = useSelector((state) => state?.authSlice?.logged);
  const [getPreference] = useLazyGetPreferenceQuery();
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);
  const [signout, { isSuccess, data: logoutData }] = useSignoutMutation();
  const [logo, setLogo] = useState("");
  const redyForSignout = useSelector((state) => state?.authSlice?.signout);
  const bearer = localStorage.getItem("user_token");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();
  /*  const [open, setOpen] = useState(false); */
  const open = useSelector((state) => state?.utilsSlice?.headerAppBarOpen);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  useEffect(() => {
    if (redyForSignout) {
      signout();
    }
  }, [redyForSignout]);
  useEffect(() => {
    if (userInfo == null && bearer) {
      getPreference();
    }
  }, [userInfo, bearer]);
  useEffect(() => {
    if (isSuccess) {
      debugger;
      window.location.replace(
        `${logoutData.logout_url}?id_token_hint=${logoutData.id_token}&post_logout_redirect_uri=${window.LOGOUT_URL}`
      );
    }
  }, [isSuccess]);
  useEffect(() => {
    console.log("Selya", selected);
  }, [selected]);
  const [items] = useState({
    devicesUUID: uuidv4(),
    digitalTwinsUUID: uuidv4(),
    groupsUUID: uuidv4(),
    districtsUUID: uuidv4(),
    codecsUUID: uuidv4(),
    aegisUUID: uuidv4(),
    usersUUID: uuidv4(),
    securityProvider: uuidv4(),
    userGroups: uuidv4(),
    middleware: uuidv4(),
    networkAdapters: uuidv4(),
    applicationDesignUUID: uuidv4(),
    developmentUUID: uuidv4(),
    applicationDesign: uuidv4(),
    custumerEndpoints: uuidv4(),
    dashboard: uuidv4(),
    meters: uuidv4(),
    metersList: uuidv4(),
    createMeter: uuidv4(),
    groups: uuidv4(),
    gis: uuidv4(),
    importExport: uuidv4(),
    alarm: uuidv4(),
    network: uuidv4(),
    dex: uuidv4(),
    userManagment: uuidv4(),
    customerConfig: uuidv4(),
    campaign: uuidv4(),
    createCampaign: uuidv4(),
    campaignList: uuidv4(),
    position: uuidv4(),
    positionList: uuidv4(),
    createPostition: uuidv4(),
    users: uuidv4(),
    createUser: uuidv4(),
    userList: uuidv4(),
    import: uuidv4(),
    importAdmin: uuidv4(),
    importReadings: uuidv4(),
    export: uuidv4(),
    exportRedings: uuidv4(),
    exportAdmin: uuidv4(),
    synteticData: uuidv4(),
    synteticCampaignList: uuidv4(),
    campaignGenerator: uuidv4(),
    districtMetering: uuidv4(),
    districtSetup: uuidv4(),
    districtImport: uuidv4(),
    districtExport: uuidv4(),
    manageLPWAN: uuidv4(),
    eventsAlarms: uuidv4(),
    messages: uuidv4(),
    livMessages: uuidv4(),
    groupCreate: uuidv4(),
    groupList: uuidv4(),
    group: uuidv4(),
    dataServices: uuidv4(),
    statistics: uuidv4(),
    statisticList: uuidv4(),
    createStatistic: uuidv4(),
    widgets: uuidv4(),
    widgetList: uuidv4(),
    widgetCreate: uuidv4(),
    addLPWAN: uuidv4(),
    exportReadings: uuidv4(),
    exportAdmin: uuidv4(),
    digitalTwins: uuidv4(),
    digitalTwinList: uuidv4(),
    connections: uuidv4(),
    connectionsList: uuidv4(),
    connectionCreate: uuidv4(),
    trilliant: uuidv4(),
    meterSeen: uuidv4(),
    probes: uuidv4(),
    surveys: uuidv4(),
    surveyList: uuidv4(),
    worklists: uuidv4(),
    worklistAqp: uuidv4(),
    createWorklistAqp: uuidv4(),
    worklistindexAqp: uuidv4(),
    wokrlistEditor: uuidv4(),
    workListEditorList: uuidv4(),
    worklistEditorCreate: uuidv4(),
    workList: uuidv4(),
    workListIndex: uuidv4(),
    workListCreate: uuidv4(),
    probesList: uuidv4(),
    createProbes: uuidv4(),
    dexIndex: uuidv4(),
    createDex: uuidv4(),
    others: uuidv4(),
    monitoring: uuidv4(),
    readings: uuidv4(),
  });

  const handleDrawerOpen = () => {
    dispatch(setHeaderAppBar());
  };

  const handleDrawerClose = () => {
    dispatch(setHeaderAppBar());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const menuItems = [
    {
      name: "groups",
      expanded: [items.digitalTwinsUUID, items.groupsUUID],
      selected: items.groupsUUID,
      children: [
        {
          name: "list_groups",
          expanded: [items.digitalTwinsUUID, items.groupsUUID],
          selected: null,
          to: "/groups",
        },
        {
          name: "create_groups",
          expanded: [items.digitalTwinsUUID, items.groupsUUID],
          selected: null,
          to: "/groups/create",
        },
      ],
    },
    {
      name: "devices",
      expanded: [items.digitalTwinsUUID, items.devicesUUID],
      selected: items.devicesUUID,
      children: [
        {
          name: "list_devices",
          expanded: [items.digitalTwinsUUID, items.devicesUUID],
          selected: null,
          to: "/devices",
        },
        {
          name: "create_devices",
          expanded: [items.digitalTwinsUUID, items.devicesUUID],
          selected: null,
          to: "/devices/create",
        },
      ],
    },
    {
      name: "districts",
      expanded: [items.digitalTwinsUUID, items.districtsUUID],
      selected: items.districtsUUID,
      children: [
        {
          name: "list_districts",
          expanded: [items.digitalTwinsUUID, items.districtsUUID],
          selected: null,
          to: "/districts",
        },
        {
          name: "create_districts",
          expanded: [items.digitalTwinsUUID, items.districtsUUID],
          selected: null,
          to: "/districts/create",
        },
      ],
    },
    {
      name: "middleware",
      expanded: [items.digitalTwinsUUID, items.codecsUUID],
      selected: items.codecsUUID,
      children: [
        {
          name: "list_middleware",
          expanded: [items.digitalTwinsUUID, items.codecsUUID],
          selected: null,
          to: "/codecs",
        },
        {
          name: "create_codecs",
          expanded: [items.digitalTwinsUUID, items.codecsUUID],
          selected: null,
          to: "/codecs/create",
        },
      ],
    },
  ];

  const resolveActive = () => {
    const item = findLocation(menuItems, location);
  };

  const findLocation = (list, location) => {
    return list.find((item) => {
      if (item.children) {
        const result = findLocation(item.children, location);
        if (result) {
          setExpanded(result.expanded);
          setSelectedMenu(result);
          setSelected(result.selected);
          return result;
        }
      }
      return item.to === location.pathname;
    });
  };
  useEffect(() => {
    console.log("slctdlmnt", expanded);
  }, [expanded]);
  useEffect(() => {
    resolveActive();
  }, [location]);
  const path = window.location.href;

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box display={"flex"} sx={{ flexGrow: 1 }} alignItems="center">
            <img
              style={{
                height: "30px",
                marginRight: 20,
                marginTop: 5,
              }}
              src={window.LOGO}
            />
            <Typography variant="h6" noWrap component="div">
              Swarm
            </Typography>
          </Box>

          {!auth.user() && (
            <Button color="inherit" to="/login" component={RouterLink}>
              Login
            </Button>
          )}
          {auth.user() && (
            <div>
              {t("welcome")} {auth.user().display_name}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => dispatch(actions.signout())}>
                  {t("logout")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/me");
                    handleClose();
                  }}
                >
                  {t("myAccount")}
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <TreeView
          aria-label="file system navigator"
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          sx={{ height: 240, flexGrow: 1, maxWidth: 410, overflowY: "auto" }}
        >
          <List>
            <IwdTreeItem
              uuid={items.dashboard}
              icon={<DashboardIcon />}
              title={t("dashboard")}
              section={"dashboard"}
              link={"/metrics"}
            />
            <IwdTreeItem
              uuid={items.devicesUUID}
              icon={<CategoryIcon />}
              title={t("devices")}
              section={"devices"}
            >
              <IwdTreeItem
                uuid={items.meters}
                icon={<GasMeterIcon />}
                title={t("meters")}
                section={"devices_meters"}
              >
                <IwdTreeItem
                  uuid={items.trilliant}
                  icon={<AssessmentIcon />}
                  title={t("Trilliant")}
                  section={"devices_trilliant"}
                  link={"/trilliant"}
                />
                <IwdTreeItem
                  uuid={items.readings}
                  icon={<MenuBookIcon />}
                  title={t("readings")}
                  section={"readings"}
                  link={"/readings"}
                />
                <IwdTreeItem
                  uuid={items.meterSeen}
                  icon={<PreviewIcon />}
                  title={t("meter_seen")}
                  link={"/meterseen"}
                  section={"meter_seen"}
                />
                <IwdTreeItem
                  uuid={items.metersList}
                  icon={<FormatListBulletedIcon />}
                  title={t("meters_list")}
                  link={"/meters"}
                  section={"devices_list"}
                />
                <IwdTreeItem
                  uuid={items.createMeter}
                  icon={<AddCircleIcon />}
                  title={t("meters_create")}
                  link={`meters/create`}
                  section={"devices_addMeter"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.probes}
                icon={<SettingsInputAntennaIcon />}
                title={t("probes")}
                section={"device_probes"}
              >
                <IwdTreeItem
                  uuid={items.probesList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  link={`/probes`}
                  section={"device_probes"}
                />
                <IwdTreeItem
                  uuid={items.createProbes}
                  icon={<AddCircleIcon />}
                  title={t("probes_create")}
                  link={"/probes/create"}
                  section={"device_probes"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.import}
                icon={<ArrowDownwardIcon />}
                title={t("data_import")}
                section={"devices_import"}
              >
                <IwdTreeItem
                  uuid={items.importAdmin}
                  icon={<AdminPanelSettingsIcon />}
                  title={t("administrative")}
                  section={"devices_import_admin"}
                />
                <IwdTreeItem
                  uuid={items.importReadings}
                  icon={<AutoStoriesIcon />}
                  title={t("meters_readings")}
                  link={"/import"}
                  section={"devices_import_readings"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.export}
                icon={<ArrowUpwardIcon />}
                title={t("data_export")}
                section={"devices_export"}
              >
                <IwdTreeItem
                  uuid={items.exportAdmin}
                  icon={<AdminPanelSettingsIcon />}
                  title={t("administrative")}
                  section={"devices_export_admin"}
                />
                <IwdTreeItem
                  uuid={items.exportReadings}
                  icon={<AutoStoriesIcon />}
                  title={t("meters_readings")}
                  section={"devices_export_readings"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.campaign}
              icon={<CampaignIcon />}
              title={t("campaign")}
              section={"services"}
            >
              <IwdTreeItem
                uuid={items.synteticData}
                icon={<FolderIcon />}
                title={t("synthetic_data")}
                section={"services_data"}
              >
                <IwdTreeItem
                  uuid={items.campaignList}
                  icon={<FormatListBulletedIcon />}
                  title={t("campaign_list")}
                  section={"campaign_list"}
                  link={"/campaigns"}
                />
                <IwdTreeItem
                  uuid={items.createCampaign}
                  icon={<BoltIcon />}
                  title={t("campaign_generator")}
                  section={"campaing_generator"}
                  link={"/campaign/create"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.districtMetering}
                icon={<ElectricMeterIcon />}
                title={t("district_metering")}
                section={"district_metering"}
              >
                <IwdTreeItem
                  uuid={items.districtSetup}
                  icon={<BrightnessLowIcon />}
                  title={t("district_setup")}
                  section={"district_setup"}
                />
                <IwdTreeItem
                  uuid={items.districtImport}
                  icon={<ArrowDownwardIcon />}
                  title={t("district_import")}
                  section={"district_import"}
                />
                <IwdTreeItem
                  uuid={items.districtExport}
                  icon={<ArrowUpwardIcon />}
                  title={t("district_export")}
                  section={"district_export"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.surveys}
              icon={<PollIcon />}
              title={t("surveys")}
              section={"surveys"}
            >
              <IwdTreeItem
                uuid={items.surveyList}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"surveys"}
                link={"/surveys"}
              />
              <IwdTreeItem
                uuid={items.importReadings}
                icon={<AddCircleIcon />}
                title={t("create_survey")}
                link={"/surveys/create"}
                section={"surveys"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.digitalTwins}
              icon={<MemoryOutlinedIcon />}
              title={t("digital_twins")}
              section={"digital_twins"}
            >
              <IwdTreeItem
                uuid={items.exportReadings}
                icon={<FormatListBulletedIcon />}
                title={t("digital_twin_list")}
                section={"digital_twin_list"}
                link={"/digitaltwins"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.users}
              icon={<ManageAccountsIcon />}
              title={t("users")}
              section={"users"}
            >
              <IwdTreeItem
                uuid={items.userList}
                icon={<FormatListBulletedIcon />}
                title={t("user_list")}
                section={"user_list"}
                link={"/users"}
              />
              <IwdTreeItem
                uuid={items.createUser}
                icon={<AddCircleIcon />}
                title={t("user_create")}
                section={"user_create"}
                link={"/user/create"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.groupsUUID}
              icon={<AppsIcon />}
              title={t("groups")}
              section={"groups"}
            >
              <IwdTreeItem
                uuid={items.groupList}
                icon={<FormatListBulletedIcon />}
                title={t("groups_list")}
                section={"groups_list"}
                link={"groups"}
              />
              <IwdTreeItem
                uuid={items.groupCreate}
                icon={<AddCircleIcon />}
                title={t("groups_create")}
                section={"groups_create"}
                link={"/groups/create"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.worklists}
              icon={<ListAltIcon />}
              title={t("Worklist")}
              section={"worklist"}
            >
              <IwdTreeItem
                uuid={items.wokrlistEditor}
                icon={<EditNoteIcon />}
                title={t("worklisteditor")}
                section={"worklist"}
              >
                <IwdTreeItem
                  uuid={items.workListEditorList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"worklist"}
                  link={"worklisteditors"}
                />
                <IwdTreeItem
                  uuid={items.worklistEditorCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_worklist_editor")}
                  section={"worklist"}
                  link={"worklisteditor/create"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.workList}
                icon={<FactCheckOutlinedIcon />}
                title={t("worklists")}
                section={"worklist"}
              >
                <IwdTreeItem
                  uuid={items.workListIndex}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"worklist"}
                  link={"worklists"}
                />
                <IwdTreeItem
                  uuid={items.workListCreate}
                  icon={<AddCircleIcon />}
                  title={t("create_worklist")}
                  section={"worklist"}
                  link={"worklists/create"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.worklistAqp}
              icon={<ListAltIcon />}
              title={t("Worklist")}
              section={"aqpworklist"}
            >
              <IwdTreeItem
                uuid={items.worklistindexAqp}
                icon={<FormatListBulletedIcon />}
                title={t("manage")}
                section={"aqpworklist"}
                link={"worklistsaqp"}
              />
              <IwdTreeItem
                uuid={items.createWorklistAqp}
                icon={<AddCircleIcon />}
                title={t("create_worklist")}
                section={"aqpworklist"}
                link={"worklistsaqp/create"}
              />
            </IwdTreeItem>

            <IwdTreeItem
              uuid={items.districtsUUID}
              disabled
              icon={<MapIcon />}
              title={t("districts")}
              section={"districts"}
            >
              <Divider />
            </IwdTreeItem>
            <IwdTreeItem
              disabled
              uuid={items.gis}
              icon={<FmdGoodIcon />}
              title={t("gis")}
              section={"gis"}
            />

            <IwdTreeItem
              disabled
              uuid={items.alarm}
              icon={<AlarmIcon />}
              title={t("alarms")}
              section={"alarms"}
            />
            <IwdTreeItem
              uuid={items.network}
              icon={<RssFeedIcon />}
              title={t("network")}
              section={"network"}
            >
              <IwdTreeItem
                uuid={items.connections}
                icon={<LeakAddIcon />}
                title={t("connections")}
                section={"connections"}
              >
                <IwdTreeItem
                  uuid={items.connectionsList}
                  icon={<FormatListBulletedIcon />}
                  title={t("connection_list")}
                  section={"connections_list"}
                  link={"/connections"}
                />
                <IwdTreeItem
                  uuid={items.connectionCreate}
                  icon={<AddCircleIcon />}
                  title={t("connection_create")}
                  section={"connections_create"}
                  link={"/connections/create"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.manageLPWAN}
                icon={<SettingsIcon />}
                title={t("manage_lpwan")}
                section={"list_LPWAN"}
                link={"/gateways"}
              />
              <IwdTreeItem
                uuid={items.addLPWAN}
                icon={<AddCircleIcon />}
                title={t("add_lpwan")}
                section={"add_LPWAN"}
                link={"/gateways/create"}
              />
              {/* <IwdTreeItem
                uuid={items.eventsAlarms}
                icon={<NotificationImportantIcon />}
                title={t("events_alarms")}
                section={"events_alarms"}
                link={"/messages"}
              />
              <IwdTreeItem
                uuid={items.livMessages}
                icon={<DraftsIcon />}
                title={t("live")}
                section={"live"}
                link={"/messages/live"}
              /> */}
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.dataServices}
              icon={<EqualizerIcon />}
              title={t("data_services")}
              section={"data_services"}
            >
              <IwdTreeItem
                uuid={items.dex}
                icon={<ExploreIcon />}
                title={t("dex")}
                section={"dex"}
                /*   link={"/dex"} */
              >
                <IwdTreeItem
                  uuid={items.dexIndex}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"dex"}
                  link={"/dex"}
                />
                <IwdTreeItem
                  uuid={items.connectionCreate}
                  icon={<AddCircleIcon />}
                  title={t("dex_create")}
                  section={"dex"}
                  link={"/dex/create"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.statistics}
                icon={<InsightsIcon />}
                title={t("statistics")}
                section={"statistics"}
              >
                <IwdTreeItem
                  uuid={items.statisticList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"statistics_list"}
                  link={"/statistics"}
                />
                <IwdTreeItem
                  uuid={items.createStatistic}
                  icon={<AddCircleIcon />}
                  title={t("add_statistic")}
                  section={"statistics_create"}
                  link={"/statistics/create"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.widgets}
                icon={<WidgetsIcon />}
                title={t("widgets")}
                section={"widgets"}
              >
                <IwdTreeItem
                  uuid={items.widgetList}
                  icon={<FormatListBulletedIcon />}
                  title={t("manage")}
                  section={"widgets_list"}
                  link={"/widgets"}
                />
                <IwdTreeItem
                  uuid={items.widgetCreate}
                  icon={<AddCircleIcon />}
                  title={t("add_widget")}
                  section={"widgets_create"}
                  link={"/widgets/create"}
                />
              </IwdTreeItem>
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.monitoring}
              icon={<DvrIcon />}
              title={t("monitoring")}
              section={"monitoring"}
            >
              <IwdTreeItem
                uuid={items.eventsAlarms}
                icon={<NotificationImportantIcon />}
                title={t("events_alarms")}
                section={"events_alarms"}
                link={"/messages"}
              />
              <IwdTreeItem
                uuid={items.livMessages}
                icon={<DraftsIcon />}
                title={t("live")}
                section={"live"}
                link={"/messages/live"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              uuid={items.others}
              icon={<DialpadIcon />}
              title={t("others")}
              section={"others"}
            >
              <IwdTreeItem
                uuid={items.exportReadings}
                icon={<FormatListBulletedIcon />}
                title={t("digital_twin_list")}
                section={"digital_twin_list"}
                link={"/digitaltwins"}
              />
            </IwdTreeItem>
            <IwdTreeItem
              disabled
              uuid={items.customerConfig}
              icon={<PermDataSettingIcon />}
              title={t("customer_config")}
              section={"customer"}
            />
          </List>
        </TreeView>
        <Box sx={{ padding: 1, color: "#6F7E8C" }}>
          Version: {process.env.REACT_APP_VERSION}
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
