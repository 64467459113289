import { StyledDataGrid } from "../../utils/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import theme from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const GatewayMessagesDetails = ({ meters, twinId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderDevice = (item) => {
    if (item && item.serial && item.version && item.type && item?.serial) {
      return `${item.manufacturer}:${item.version}:${item.type}  ${item?.serial}`;
    } else if (item && item.serial) {
      return `Serial: ${item?.serial} `;
    } else {
      return "na";
    }
  };
  const normalColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("meter"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      headerAlign: "left",
      wrapText: true,
    },
    {
      field: "handled",
      headerName: t("handled_meters"),
      width: 90,
      sortable: false,
      flex: 1,

      headerAlign: "left",
      renderCell: (params) => {
        return params.value === true ? (
          <DoneIcon sx={{ fill: theme.palette.success.main }} />
        ) : (
          <CloseIcon sx={{ fill: theme.palette.error.main }} />
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
            sx={{ mr: 1, maxWidth: 900 }}
          >
            {params?.row?.handled && (
              <IconButton onClick={() => navigate(`/meters/${params.value}`)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    console.log("vaflers", meters);
  }, [meters]);
  const normalRows = meters?.map((item, i) => ({
    id: i,
    device: renderDevice(item),

    message_date: t("date_val", { val: new Date(item?.datetime_utc) }),
    handled: item?.handled,
    actions: item?.uuid,
  }));
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "device",
      headerName: t("meter"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "rssi",
      headerName: t("Rssi"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 1,
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      width: 100,
      headerAlign: "left",
      wrapText: true,
    },
    {
      field: "handled",
      headerName: t("handled_meters"),
      width: 90,
      sortable: false,
      flex: 1,

      headerAlign: "left",
      renderCell: (params) => {
        return params.value === true ? (
          <DoneIcon sx={{ fill: theme.palette.success.main }} />
        ) : (
          <CloseIcon sx={{ fill: theme.palette.error.main }} />
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
            sx={{ mr: 1, maxWidth: 900 }}
          >
            {params?.row?.handled && (
              <IconButton onClick={() => navigate(`/meters/${params.value}`)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const rows = meters?.map((item, i) => ({
    id: i,
    device: renderDevice(item),
    rssi: item?.rssi,
    message_date: t("date_val", { val: new Date(item?.datetime_utc) }),
    handled: item?.handled,
    actions: item?.uuid,
  }));
  return (
    <Box sx={{ height: "60vh", width: "70vw" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={twinId == 62 ? rows : normalRows}
        columns={twinId == 62 ? columns : normalColumns}
        /*    rowCount={meters.length} */
        /*      pageSize={perPage}
      rowsPerPageOptions={[perPage]} */
        disableSelectionOnClick
        /*       onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1} */
      />
    </Box>
  );
};

export default GatewayMessagesDetails;
