import qs from "qs";
import { LatLng } from "leaflet";

/**
 * From bound return the distances (zoomLevel)
 * @param bounds Bounds
 *
 * @returns Integer the zoom level
 */
export const getDistance = ({
  _northEast: { lat: ne_lat, lng: ne_lng },
  _southWest: { lat: sw_lat, lng: sw_lng },
}) => {
  const ne = new LatLng(ne_lat, ne_lng);
  const sw = new LatLng(sw_lat, sw_lng);
  return ne.distanceTo(sw) / 60;
};

export const createPostRequest = (attributes, type) => {
  return {
    data: {
      attributes: attributes,
      type: type,
    },
  };
};

export const prepareInAreaDevicesQueryString = (
  {
    _northEast: { lat: ne_lat, lng: ne_lng },
    _southWest: { lat: sw_lat, lng: sw_lng },
  },
  zoomLevel
) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
    single_cluster: {
      zoom_level: zoomLevel,
    },
  };
};

export const prepareInAreaClustersQueryString = (
  {
    _northEast: { lat: ne_lat, lng: ne_lng },
    _southWest: { lat: sw_lat, lng: sw_lng },
  },
  zoomLevel
) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
    zoom_level: zoomLevel,
  };
};
export const prepareInAreaQueryString = ({
  _northEast: { lat: ne_lat, lng: ne_lng },
  _southWest: { lat: sw_lat, lng: sw_lng },
}) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
  };
};

export const convertParamsToString = (params) => {
  return qs.stringify(params);
};

export const prepareParams = (obj) => {
  let params = new URLSearchParams();
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      params.set(`${key}`, obj[key]);
    } else {
      if (typeof obj[key] === "object") {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params.set(`${skey}`, tmp[skey]);
          }
        }
      } else {
        if (obj[key]) {
          {
            params.set(`${key}`, obj[key]);
          }
        }
      }
    }
  }
  return params;
};

export const prepareBody = (attributes, id, type) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
      },
      id: id,
      type: type,
    },
  };
  return body;
};

export const prepareBodyWithoutId = (attributes, type) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
      },
      type: type,
    },
  };
  return body;
};
export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);
  // console.log(d, h, m, s)
  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days ") : "";

  return dDisplay;
}

export function hasCommonValue(arr1, arr2) {
  return arr1.some((value) => arr2?.includes(value));
}
export function removeDuplicateObjects(array, keyFn) {
  debugger;
  const seen = new Set();
  return array.filter((item) => {
    const key = keyFn(item);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export const getDateDifferenceInDays = (date) => {
  const now = new Date();
  const currentDate = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
  );
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  const readTimeDate = new Date(date);
  readTimeDate.setHours(0);
  readTimeDate.setMinutes(0);
  readTimeDate.setSeconds(0);
  readTimeDate.setMilliseconds(0);
  const diffInMs = Math.abs(currentDate - readTimeDate);
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  console.log("ebal", diffInDays);
  return diffInDays;
};
export function timeAgo(seconds) {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";
  /* if (days > 0) { */
  days !== 1
    ? (formattedTime += days + " giorni, ")
    : (formattedTime += days + " giorno, ");
  /*  } */
  formattedTime +=
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    remainingSeconds.toString().padStart(2, "0");

  return formattedTime;
}
