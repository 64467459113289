import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";

import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import theme from "../../theme";

const MeterReadingsGraph = ({ meter, unit }) => {
  const { t } = useTranslation();

  const data = useSelector((state) =>
    state.meterReadingSlice?.data?.map((item) => {
      let numericValue = item.reading;
      if (typeof item.reading === "string") {
        numericValue = parseFloat(item.reading.replace(",", "."));
        console.log("hapnulhuya", typeof numericValue);
      }

      return {
        oneDay: new Date(item?.oneDay).getTime(),
        reading: isNaN(numericValue)
          ? "Nessuna lettura"
          : unit == "m3"
          ? numericValue
          : numericValue * 1000,
      };
    })
  );
  useEffect(() => {
    console.log("dataRecharts", data);
  }, [data]);

  const [ref, { width }] = useMeasure();

  if (!data) {
    return <Box ref={ref}>Loading</Box>;
  }

  return (
    <Box ref={ref}>
      <LineChart
        style={{ margin: 4 }}
        width={width - 10}
        height={400}
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        {/*  <Tooltip
          labelFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
        ></Tooltip> */}
        <Line
          type="monotone"
          dataKey="reading"
          stroke={theme.palette.network.main}
          connectNulls={true}
        />
        <CartesianGrid stroke={theme.palette.device.main} />
        <XAxis
          dataKey="oneDay"
          label={{ value: t("date"), position: "insideBottom", offset: 0 }}
          angle={45}
          height={90}
          tickMargin={30}
          interval="preserveStartEnd"
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          stroke={theme.palette.group_and_district.main}
        />
        <YAxis
          label={{ value: t("volume"), angle: -90, position: "insideLeft" }}
          stroke={theme.palette.group_and_district.main}
        />
        <Tooltip />
      </LineChart>
    </Box>
  );
};

export default MeterReadingsGraph;
