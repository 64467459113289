import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import DraftsIcon from "@mui/icons-material/Drafts";
import theme from "../../theme";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import CloseIcon from "@mui/icons-material/Close";

import ModalDialog from "../meters/ModalDialog";
import AntennasMap from "./AntennasMap";
import { useDispatch, useSelector } from "react-redux";
import DeviceMap from "../metrics/MapLeaflet";
import MapIcon from "@mui/icons-material/Map";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetAntennasMessagesQuery } from "../../redux/slices/deviceMessageApi";
const AntennasMessages = ({ meter, mapTab = false, fromSurvey }) => {
  const [devicePos, setDevicePos] = useState([]);
  const [gatewayList, setGatewayList] = useState([]);
  const [gatewaysPosition, setGatewaysPosition] = useState([]);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const antennasMessage = useSelector(
    (state) => state?.messageSlice?.antennasMessage
  );
  const antennasMessageCount = useSelector(
    (state) => state?.messageSlice?.antennasMessageCount
  );
  const [messageModal, setMessageModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [message, setMessage] = useState();
  const [getMessages, { isLoading }] = useLazyGetAntennasMessagesQuery();
  const [trigger, result, lastPromiseInfo, isSuccess] = useLazyGetDataQuery();
  useEffect(() => {
    getMessages({
      digitalTwinId: meter?.attributes?.digital_twin_id,
      deviceId: meter?.id,
      page: page,
      perPage: perPage,
    });
  }, [page]);
  const openMessageModal = () => {
    setMessageModal(true);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
    setMessage("");
  };
  const onMailClick = (msg) => {
    setMessage(msg);
    openMessageModal();
  };
  useEffect(() => {
    console.log("datusya", meter);
  }, [meter]);
  useEffect(() => {
    if (gatewayList.length > 0) {
      setGatewaysPosition([]);
      gatewayList.map((item) => {
        trigger(`/altior/digital_twin/0/instance/${item}`)
          ?.unwrap()
          ?.then((data) =>
            setGatewaysPosition((prev) => [
              ...prev,
              data?.data?.attributes?.coordinates.coordinates,
            ])
          )
          .catch((er) => console.log(er));
      });
    }
  }, [gatewayList]);
  const rssiColorSelector = (rssi) => {
    if (rssi > -80) {
      return theme.palette.success.main;
    }
    if (rssi > -81 && rssi < -110) {
      return theme.palette.warning.main;
    }
    if (rssi < -140) {
      return theme.palette.error.main;
    }
  };
  const rssiPositions = antennasMessage
    .filter((item) => item?.attributes?.values?.values?.value?.rssi)
    ?.map((item) => ({
      coordinates:
        item?.attributes?.values?.values?.value?.position?.coordinates ??
        item?.attributes?.values?.values?.position?.coordinates,
      rssi: item?.attributes?.values?.values?.value?.rssi,
      spreading_factor:
        item?.attributes?.values?.values?.value?.spreading_factor,
      color: rssiColorSelector(item?.attributes?.values?.values?.value?.rssi),
    }));
  useEffect(() => {
    console.log("rskapos", rssiPositions);
  }, [rssiPositions]);
  const onMapClick = (obj) => {
    debugger;
    setDevicePos(
      obj?.attributes?.values?.values?.value?.position?.coordinates ??
        obj?.attributes?.values?.values?.position?.coordinates
    );
    let meggaseId =
      obj?.attributes?.values?.values?.value?.message_id ??
      obj?.attributes?.values?.values?.message_id;

    setGatewayList([
      ...new Set(
        antennasMessage
          .filter((item) =>
            item?.attributes.values.values.value
              ? item?.attributes.values.values.value.message_id === meggaseId
              : item?.attributes.values.values.message_id == meggaseId
          )
          .map((item) =>
            item?.attributes?.values?.values?.value
              ? item?.attributes?.values?.values?.value.gateway_identifier
              : item?.attributes?.values?.values?.gateway_identifier
          )
      ),
    ]);
  };
  useEffect(() => {
    console.log("dvps", gatewaysPosition);
  }, [gatewaysPosition]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 3 : 1,
      editable: false,
    },
    {
      field: "message_type",
      headerName: t("message_type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 3 : 1,
      editable: false,
    },
    {
      field: "gateway",
      headerName: t("gateway"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 3 : 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-start"}>
            {params?.value ? (
              <IconButton onClick={() => navigate(`/gateways/${params.value}`)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            ) : (
              <CloseIcon sx={{ fill: theme.palette.error.main, ml: 1 }} />
            )}
          </Box>
        );
      },
    },

    {
      field: "values",
      headerName: t("values"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 4 : 2,
      editable: false,
      renderCell: (params) => {
        const cellStyles = {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        };
        return (
          <Typography style={cellStyles}>
            {JSON.stringify(params.value)}
          </Typography>
        );
      },
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
            width={"100%"}
            sx={{ ml: 5.5 }}
          >
            <IconButton onClick={() => onMailClick(params?.value)}>
              <DraftsIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
            {mapTab &&
              (params?.value?.attributes?.values?.values?.position ||
                params?.value?.attributes?.values?.values?.value?.position) && (
                <IconButton onClick={() => onMapClick(params?.value)}>
                  <MapIcon sx={{ fill: theme.palette.primary.main }} />
                </IconButton>
              )}
          </Box>
        );
      },
    },
  ];

  const rows = antennasMessage?.map((item, i) => ({
    id: i,
    gateway:
      item?.attributes?.values?.values?.gateway_identifier ??
      item?.attributes?.values?.values?.value?.gateway_identifier,
    message_type: item?.attributes?.message_type,
    message_date:
      t("dt", { val: new Date(item?.attributes?.message_date) }) ?? "--",
    values: item,
    actions: item,
  }));
  const surveyColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "message_date",
      headerName: t("message_date"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "rssi",
      headerName: t("rssi"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "spreading_factor",
      headerName: t("spreading_factor"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "coordinates",
      headerName: t("coordinates"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        return (
          <Box height={"150%"}>
            <Typography>{`Latitude: ${params.value[1]}`}</Typography>
            <Typography
              sx={{ mt: 0.2 }}
            >{`Longitude: ${params.value[0]}`}</Typography>
          </Box>
        );
      },
    },
    /*  {
      field: "message_type",
      headerName: t("message_type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 3 : 1,
      editable: false,
    }, */
    {
      field: "gateway",
      headerName: t("gateway"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-start"}>
            {params?.value ? (
              <IconButton onClick={() => navigate(`/gateways/${params.value}`)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            ) : (
              <CloseIcon sx={{ fill: theme.palette.error.main, ml: 1 }} />
            )}
          </Box>
        );
      },
    },

    /*  {
      field: "values",
      headerName: t("values"),
      type: "string",
      sortable: false,
      editable: false,
      flex: !mapTab ? 4 : 2,
      editable: false,
      renderCell: (params) => {
        const cellStyles = {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        };
        return (
          <Typography style={cellStyles}>
            {JSON.stringify(params.value)}
          </Typography>
        );
      },
    }, */

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
            width={"100%"}
            sx={{ ml: 5.5 }}
          >
            {mapTab &&
              (params?.value?.attributes?.values?.values?.position ||
                params?.value?.attributes?.values?.values?.value?.position) && (
                <IconButton onClick={() => onMapClick(params?.value)}>
                  <MapIcon sx={{ fill: theme.palette.primary.main }} />
                </IconButton>
              )}
            <IconButton onClick={() => onMailClick(params?.value)}>
              <DraftsIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    console.log("fromSurvay", fromSurvey);
  }, [fromSurvey]);
  const surveyRows = antennasMessage?.map((item, i) => ({
    id: i,
    spreading_factor:
      item?.attributes?.values?.values?.value?.spreading_factor ?? "--",
    gateway:
      item?.attributes?.values?.values?.gateway_identifier ??
      item?.attributes?.values?.values?.value?.gateway_identifier,
    rssi: item?.attributes?.values?.values?.value?.rssi ?? "--",
    coordinates:
      item?.attributes?.values?.values?.value?.position?.coordinates ?? "--",
    message_date:
      t("dt", { val: new Date(item?.attributes?.message_date) }) ?? "--",

    actions: item,
  }));
  return (
    <>
      {!mapTab && (
        <Box sx={{ height: 650 }}>
          <ModalDialog
            open={messageModal}
            close={() => closeMessageModal()}
            title={"Message"}
          >
            <Box width={"100%"}>
              <Typography variant="subtitle2">
                <pre
                  style={{
                    overflow: "auto",
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: "9px",
                  }}
                >
                  {JSON.stringify(message, null, 10)}
                </pre>
              </Typography>
            </Box>
          </ModalDialog>
          {rows && !isLoading && (
            <StyledDataGrid
              disableColumnMenu
              rows={!fromSurvey ? rows : surveyRows}
              columns={!fromSurvey ? columns : surveyColumns}
              pageSize={perPage}
              rowCount={antennasMessageCount}
              rowsPerPageOptions={[perPage]}
              disableSelectionOnClick
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              paginationMode="server"
            />
          )}
        </Box>
      )}
      {mapTab && (
        <Box
          sx={{ height: 650, width: appBarIsOpen ? "82vw" : "93vw" }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <ModalDialog
            open={messageModal}
            close={() => closeMessageModal()}
            title={"Message"}
          >
            <Box width={"100%"}>
              <Typography variant="subtitle2">
                <pre
                  style={{
                    overflow: "auto",
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: "9px",
                  }}
                >
                  {JSON.stringify(message, null, 10)}
                </pre>
              </Typography>
            </Box>
          </ModalDialog>
          {rows && !isLoading && (
            <Box width={"50%"}>
              <StyledDataGrid
                disableColumnMenu
                rows={!fromSurvey ? rows : surveyRows}
                columns={!fromSurvey ? columns : surveyColumns}
                pageSize={perPage}
                rowCount={antennasMessageCount}
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                paginationMode="server"
              />
            </Box>
          )}
          <Box width={"50%"} sx={{ ml: 5 }}>
            <AntennasMap
              gatewayPos={gatewaysPosition}
              devicePos={devicePos}
              rssiPositions={rssiPositions}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
export default AntennasMessages;
