import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Users from "../components/users/Users";
import UserShow from "../components/users/UserShow";
import Metrics from "../pages/Metrics";
import TrilliantMeters from "../components/campaign/trilliantMeters/TrilliantMeters";
import Meters from "../components/form/Meters";
import MeterCreate from "../components/form/MeterCreate";
import Uploader from "../components/FileUploader /Uploader";
import UploadedFileShow from "../components/FileUploader /UploadedFileShow";
import Campaigns from "../components/campaign/Campaigns";
import CampaignShow from "../components/campaign/CampaignShow";
import CampaignCreate from "../components/campaign/CampaignCreate";
import AntennasCreate from "../components/antennas/CreateAntennas";
import DigitalTwins from "../components/digital_twins/DigitalTwins";
import MyAccount from "../pages/MyAccount";
import DigitalTwinShow from "../components/digital_twins/DigitalTwinShow";
import UserCreate from "../components/users/UserCreate";
import WorklistCreate from "../components/worklist/WorklistCreate";
import DistrictsIndex from "../components/DeviceGroups/DistrictsIndex";
import GroupDeviceCreate from "../components/DeviceGroups/GroupDeviceCreate";
import GroupsDeviceShow from "../components/DeviceGroups/GroupsDeviceShow";
import WorkListEditorCreate from "../components/worklist/WorkListEditorCreate";
import SurveyShow from "../components/survey/SurveyShow";
import Condominium from "../components/condominums/Condominium";
import Connections from "../components/connections/Connections";
import ConnectionCreate from "../components/connections/ConnectionCreate";
import ConnectionsShow from "../components/connections/ConnectionsShow";
import GatewayIndex from "../components/gateway/GatewayIndex";
import GatewayCreate from "../components/gateway/GatewayCreate";
import MeterSeenIndex from "../components/MeterSeenIndex";
import GatewayShow from "../components/gateway/GatewayShow";
import { SubCondominiumShow } from "../components/condominums/SubCondominiumShow";
import Messages from "../components/messages/Messages";
import LiveMessages from "../components/messages/LiveMessages";
import Dex from "../pages/Dex";
import ApartamentMeterShow from "../components/condominums/ApartamentMeterShow";
import { CondominiumShow } from "../components/condominums/CondominiumShow";
import SurveyIndex from "../components/survey/SurveyIndex";
import StatisticShow from "../components/statistics/StatisticShow";
import Statistics from "../components/statistics/Statistics";
import StatisticCreate from "../components/statistics/StatisticCreate";
import CreateWidget from "../components/widgets/CreateWidget";
import WidgetShow from "../components/widgets/WidgetShow";
import Widgets from "../components/widgets/Widgets";
import MeterShow from "../components/metrics/MeterShow";
import AntennasShow from "../components/antennas/AntennasShow";
import DashboardCreate from "../components/dashboard/DashboardCreate";
import SwarmApp from "../components/swarmApp/SwarmApp";
import App from "../App";
import AntennasIndex from "../components/antennas/AntennasIndex";
import NotFoundPage from "./NotfoundPage";
import LoginPage from "../components/Login";
import Logged from "../components/Logged";
import AllLastReadings from "../components/form/AllLastReadings";
import WorklistIndexAqp from "../components/worklist/worklistAqp/WorklistIndexAqp";
import { ApartmentShow } from "../components/condominums/ApartmentShow";
import WorklistEditorShow from "../components/worklist/WorklistEditorShow";
import WorklistEditorIndex from "../components/worklist/WorklistEditorIndex";
import CreateSurvey from "../components/survey/CreateSurvey";
import WorklistIndex from "../components/worklist/WorklistIndex";
import WorklistShow from "../components/worklist/WorklistShow";
import MeterUpdate from "../components/metrics/MeterUpdate";
import WorklistAqpCreate from "../components/worklist/worklistAqp/WorklistAqpCreate";
import WorklistShowAqp from "../components/worklist/worklistAqp/WorklistShowAqp";
import DexIndex from "../pages/DexIndex";
import DexShow from "../pages/DexShow";

const selectMainRoot = (str) => {
  debugger;
  switch (str) {
    case "Metrics":
      return <Metrics />;
    case "Meters":
      return <Meters />;
    case "Survay":
      return <SurveyIndex />;
    case "DigitalTwin":
      return <DigitalTwins />;
    case "Users":
      return <Users />;
    case "Groups":
      return <DistrictsIndex />;
    case "Worklisteditors":
      return <WorklistEditorIndex />;
    case "Worklists":
      return <WorklistIndex />;
    case "Connections":
      return <Connections />;
    case "Gateways":
      return <GatewayIndex />;
    case "Messages":
      return <Messages />;
    case "Live":
      return <LiveMessages />;
    case "Dex":
      return <Dex />;
    case "Statistics":
      return <Statistics />;
    case "Widgets":
      return <Widgets />;
    case "worklistAqp":
      return <WorklistIndexAqp />;
  }
};
const RootSelector = () => {
  const findLowestPriorityRoot = (dataArray, object) => {
    let lowestPriority = Infinity;
    let lowestPriorityRoot = null;

    for (const element of dataArray) {
      if (
        object.hasOwnProperty(element) &&
        object[element].priority < lowestPriority
      ) {
        lowestPriority = object[element].priority;
        lowestPriorityRoot = object[element].root;
      }
    }

    return lowestPriorityRoot;
  };

  const rotes = window.ROOTES;

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const result = findLowestPriorityRoot(roles, rotes);
  useEffect(() => {
    console.log("resik", result);
  }, [result]);

  return selectMainRoot(result ?? "Meters");
};
export const IwdRoutes = [
  /*  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName/apartments/:apartmentName/apartamentMeter/:serial",
    auth: true,
    component: <ApartamentMeterShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName/apartments/:apartmentName",
    auth: true,
    component: <ApartmentShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/:id/subcondominiums/:subCondominumName",
    auth: true,
    component: <SubCondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/new",
    auth: true,
    component: <CondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums/:id",
    auth: true,
    component: <CondominiumShow />,
    section: "condominums",
  },
  {
    path: "/condominiums",
    auth: true,
    component: <Condominium />,
    section: "condominums",
  }, */
  {
    path: "/worklists/:id",
    auth: true,
    component: <WorklistShow />,
    section: "worklist",
  },
  {
    path: "/readings",
    auth: true,
    component: <AllLastReadings />,
    section: "readings",
  },
  {
    path: "/worklistsaqp/:id",
    auth: true,
    component: <WorklistShowAqp />,
    section: "aqpworklist",
  },
  {
    path: "/me",
    auth: true,
    component: <MyAccount />,
    section: "account",
  },
  {
    path: "/worklists",
    auth: true,
    component: <WorklistIndex />,
    section: "worklist",
  },
  {
    path: "/worklistsaqp",
    auth: true,
    component: <WorklistIndexAqp />,
    section: "aqpworklist",
  },
  {
    path: "/worklists/create",
    auth: true,
    component: <WorklistCreate />,
    section: "worklist",
  },
  {
    path: "/worklistsaqp/create",
    auth: true,
    component: <WorklistAqpCreate />,
    section: "aqpworklist",
  },
  {
    path: "/worklisteditor/create",
    auth: true,
    component: <WorkListEditorCreate />,
    section: "worklist",
  },
  {
    path: "/worklisteditors/:id",
    auth: true,
    component: <WorklistEditorShow />,
    section: "worklist",
  },
  {
    path: "/worklisteditors",
    auth: true,
    component: <WorklistEditorIndex />,
    section: "worklist",
  },
  { path: "/users", auth: true, component: <Users />, section: "users" },
  {
    path: "/swarmapp",
    auth: true,
    component: <SwarmApp />,
    section: "swarmapp",
  },
  {
    path: "/app",
    auth: false,
    component: <App />,
    section: "app",
  },
  {
    path: "/login",
    auth: false,
    component: <LoginPage />,
    section: "login",
  },
  {
    path: "/logged/:token",
    auth: false,
    component: <Logged />,
    section: "login",
  },
  {
    path: "/*",
    auth: false,
    component: <NotFoundPage />,
    section: "notFoundPage",
  },
  {
    path: "/surveys",
    auth: true,
    component: <SurveyIndex />,
    section: "surveys",
  },
  {
    path: "/surveys/:id",
    auth: true,
    component: <SurveyShow />,
    section: "surveys",
  },
  {
    path: "/surveys/create",
    auth: true,
    component: <CreateSurvey />,
    section: "surveys",
  },
  {
    path: "/users/:id",
    auth: true,
    component: <UserShow />,
    section: "userShow",
  },
  {
    path: "/user/create",
    auth: true,
    component: <UserCreate />,
    section: "user_create",
  },
  {
    path: "/",
    auth: true,
    component: <RootSelector />,
    section: "main",
  },
  {
    path: "/metrics",
    auth: true,
    component: <Metrics />,
    section: "dashboard",
  },
  {
    path: "/metrics/create",
    auth: true,
    component: <DashboardCreate />,
    section: "dashboard",
  },
  {
    path: "/trilliant",
    auth: true,
    component: <TrilliantMeters />,
    section: "devices_trilliant",
  },
  {
    path: "/meters",
    auth: true,
    component: <Meters />,
    section: "devices_meters",
  },
  {
    path: "/meters/:id",
    auth: true,
    component: <MeterShow />,
    section: "devices_meters",
  },
  {
    path: "/meters/:id/update",
    auth: true,
    component: <MeterUpdate />,
    section: "devices_meters",
  },
  {
    path: "/meters/create",
    auth: true,
    component: <MeterCreate />,
    section: "devices_addMeter",
  },
  {
    path: "/meterseen",
    auth: true,
    component: <MeterSeenIndex />,
    section: "meter_seen",
  },
  {
    path: "/probes",
    auth: true,
    component: <AntennasIndex />,
    section: "device_probes",
  },
  {
    path: "/probes/create",
    auth: true,
    component: <AntennasCreate />,
    section: "device_probes",
  },
  {
    path: "/probes/:id",
    auth: true,
    component: <AntennasShow />,
    section: "device_probes",
  },
  {
    path: "/import",
    auth: true,
    component: <Uploader />,
    section: "devices_import_readings",
  },
  {
    path: "/import/show",
    auth: true,
    component: <UploadedFileShow />,
    section: "devices_import_readings",
  },
  {
    path: "/campaigns",
    auth: true,
    component: <Campaigns />,
    section: "campaign_list",
  },
  {
    path: "/campaigns/:id",
    auth: true,
    component: <CampaignShow />,
    section: "campaign_list",
  },
  {
    path: "/campaign/create",
    auth: true,
    component: <CampaignCreate />,
    section: "campaing_generator",
  },
  {
    path: "/digitaltwins",
    auth: true,
    component: <DigitalTwins />,
    section: "digital_twin_list",
  },
  {
    path: "/digitaltwins/:id",
    auth: true,
    component: <DigitalTwinShow />,
    section: "digital_twin_list",
  },
  {
    path: "/groups",
    auth: true,
    component: <DistrictsIndex />,
    section: "groups_list",
  },
  {
    path: "/groups/:id",
    auth: true,
    component: <GroupsDeviceShow />,
    section: "groups_list",
  },
  {
    path: "/groups/create",
    auth: true,
    component: <GroupDeviceCreate />,
    section: "groups_create",
  },
  {
    path: "/connections",
    auth: true,
    component: <Connections />,
    section: "connections_list",
  },
  {
    path: "/connections/:id",
    auth: true,
    component: <ConnectionsShow />,
    section: "connections_list",
  },
  {
    path: "/connections/create",
    auth: true,
    component: <ConnectionCreate />,
    section: "connections_create",
  },
  {
    path: "/connections",
    auth: true,
    component: <Connections />,
    section: "connections_list",
  },
  {
    path: "/connections/:id",
    auth: true,
    component: <ConnectionsShow />,
    section: "connections_list",
  },
  {
    path: "/connections/create",
    auth: true,
    component: <ConnectionCreate />,
    section: "connections_create",
  },
  {
    path: "/gateways",
    auth: true,
    component: <GatewayIndex />,
    section: "list_LPWAN",
  },
  {
    path: "/gateways/:id",
    auth: true,
    component: <GatewayShow />,
    section: "list_LPWAN",
  },
  {
    path: "/gateways/create",
    auth: true,
    component: <GatewayCreate />,
    section: "add_LPWAN",
  },
  {
    path: "/messages",
    auth: true,
    component: <Messages />,
    section: "events_alarms",
  },
  {
    path: "/messages/live",
    auth: true,
    component: <LiveMessages />,
    section: "live",
  },
  {
    path: "/dex",
    auth: true,
    component: <DexIndex />,
    section: "dex",
  },
  {
    path: "/dex/:id",
    auth: true,
    component: <DexShow />,
    section: "dex",
  },
  {
    path: "/dex/create",
    auth: true,
    component: <Dex />,
    section: "dex",
  },
  {
    path: "/statistics",
    auth: true,
    component: <Statistics />,
    section: "statistics_list",
  },
  {
    path: "/statistics/:id",
    auth: true,
    component: <StatisticShow />,
    section: "statistics_list",
  },
  {
    path: "/statistics/create",
    auth: true,
    component: <StatisticCreate />,
    section: "statistics_create",
  },
  {
    path: "/widgets",
    auth: true,
    component: <Widgets />,
    section: "widgets_list",
  },
  {
    path: "/widgets/:id",
    auth: true,
    component: <WidgetShow />,
    section: "widgets_list",
  },
  {
    path: "/widgets/create",
    auth: true,
    component: <CreateWidget />,
    section: "widgets_create",
  },
];
