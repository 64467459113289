import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../utils/StyledDataGrid";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { deviceMessageApi } from "../../redux/slices/deviceMessageApi";
const WorklistEditorShowTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = [10];
  const navigate = useNavigate();
  const devices = useSelector(
    (state) => state?.worklistSlice?.worklistEditor
  )?.devices;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      /*  hide: true, */
    },
    {
      field: "serial",
      headerName: t("serial_number"),
      width: 90,
      sortable: false,
      flex: 1,
      /*  hide: true, */
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            sx={{ width: "100%" }}
            justifyContent={"flex-end"}
          >
            <IconButton onClick={() => navigate(`/meters/${params?.value}`)}>
              <VisibilityIcon
                sx={{
                  mt: 1,
                  fill: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = devices?.map((device) => ({
    id: device?.id,
    serial: device?.serial_number,
    actions: device?.id,
  }));
  return (
    <Box height={600}>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns ?? []}
        rowCount={devices?.length}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="client"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};
export default WorklistEditorShowTable;
